import gql from 'graphql-tag';
import { LazyQueryHookOptions, MutationOptions } from '@apollo/client';
import { useLocalLazyQuery, useLocalQuery, useLocalMutation } from 'common/hooks/apollo';
import { Group, User } from './types';

export const MyGroupInfoQuery = gql`
  query {
    myGroup {
      id
      code
      advisorId {
        email
        profile {
          firstName
          lastName
        }
      }
      invitations {
        id
      }
      users {
        id
        profile {
          firstName
          lastName
        }
        email
        location
      }
    }
  }
`;

export const useMyGroup = (options: LazyQueryHookOptions = {}) => useLocalLazyQuery(MyGroupInfoQuery, options);

export const groupesQuery = gql`
  query Groupes($codes: [String], $page: Int, $perPage: Int) {
    groupes(codes: $codes, page: $page, perPage: $perPage) {
      totalPages
      count
      page
      perPage
      data {
        id
        title
        code
        createdAt
        users {
          id
        }
        invitations {
          id
        }
      }
    }
  }
`;

interface GroupesParams {
  codes?: string[];
  page?: number;
  perPage?: number;
}

interface GroupesResponse {
  groupes: {
    page: number;
    perPage: number;
    count: number;
    totalPages: number;
    data: Group[];
  };
}

export const useGroups = (options: LazyQueryHookOptions<GroupesResponse, GroupesParams> = {}) =>
  useLocalQuery(groupesQuery, options);

export const MyRecentInscr = gql`
  {
    myRecentInscr {
      user {
        profile {
          firstName
          lastName
        }
        id
        logo
        createdAt
      }
      info {
        text
        date
      }
    }
  }
`;

interface GetMyRecentInscrResponse {
  myRecentInscr: {
    user: {
      profile: {
        firstName: string;
        lastName: string;
      };
      id: string;
      logo: string;
      createdAt: string;
    };
    info: {
      text: string;
      date: Date;
    };
  }[];
}
export const useMyRecentInscr = (options: LazyQueryHookOptions<GetMyRecentInscrResponse> = {}) =>
  useLocalLazyQuery<GetMyRecentInscrResponse>(MyRecentInscr, options);

export const lazyGroupesQuery = gql`
  query Groupes($codes: [String]) {
    groupes(codes: $codes) {
      data {
        id
        code
        title
        advisorId {
          email
          profile {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

interface LazyGroupesParams {
  codes?: string[];
}

interface LazyGroupesResponse {
  groupes: {
    data: { id: string; code: string; advisorId: User; title: string }[];
  };
}

export const useLazyGroups = (options: LazyQueryHookOptions<LazyGroupesResponse, LazyGroupesParams> = {}) =>
  useLocalLazyQuery(lazyGroupesQuery, options);

export const createGroupMutation = gql`
  mutation CreateGroup($title: String!) {
    createGroup(title: $title) {
      id
      code
      title
    }
  }
`;

interface CreateGroupMutationParams {
  title: string;
}

interface CreateGroupMutationResponse {
  createGroup: Omit<Group, 'users'>;
}

export const useCreateGroup = (options?: MutationOptions<CreateGroupMutationResponse, CreateGroupMutationParams>) =>
  useLocalMutation(createGroupMutation, options);

export const inviteToGroupMutation = gql`
  mutation InviteToGroup($email: String!, $code: String!) {
    inviteToGroup(email: $email, code: $code)
  }
`;

interface InviteToGroupParams {
  email: string;
  code: string;
}

interface InviteToGroupResponse {
  inviteToGroup: string;
}

export const useInviteToGroup = (options?: MutationOptions<InviteToGroupResponse, InviteToGroupParams>) =>
  useLocalMutation(inviteToGroupMutation, options);
/* export const getInvitation = gql`
  query getInvitations {
    getInvitations {
      date
    }
  }
`;
export const useGetInvitations = (options: LazyQueryHookOptions = {}) => useLocalLazyQuery(getInvitation, options); */
