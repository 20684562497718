/* eslint-disable max-len */
import React from 'react';

interface IProps {
  color: string;
}
const DrawerStructure = ({ color }: IProps) => {
  return (
    <svg width="33" height="26" viewBox="0 0 121 59" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44.6621 58.9587V48.2881C44.6621 39.527 51.7759 32.4507 60.4996 32.4507C69.2607 32.4507 76.337 39.5644 76.337 48.2881V58.9587"
        stroke={color}
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M86.9023 58.9587V48.2881C86.9023 39.527 94.0161 32.4507 102.74 32.4507C111.501 32.4507 118.577 39.5644 118.577 48.2881V58.9587"
        stroke={color}
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M2.42383 58.9587V48.2881C2.42383 39.527 9.53757 32.4507 18.2613 32.4507C27.0224 32.4507 34.0987 39.5644 34.0987 48.2881V58.9587"
        stroke={color}
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M61.2525 20.2386C66.2514 20.2386 70.3038 16.1862 70.3038 11.1873C70.3038 6.1884 66.2514 2.13599 61.2525 2.13599C56.2536 2.13599 52.2012 6.1884 52.2012 11.1873C52.2012 16.1862 56.2536 20.2386 61.2525 20.2386Z"
        stroke={color}
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M101.989 20.2386C106.988 20.2386 111.04 16.1862 111.04 11.1873C111.04 6.1884 106.988 2.13599 101.989 2.13599C96.9899 2.13599 92.9375 6.1884 92.9375 11.1873C92.9375 16.1862 96.9899 20.2386 101.989 20.2386Z"
        stroke={color}
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M17.5103 20.2386C22.5092 20.2386 26.5616 16.1862 26.5616 11.1873C26.5616 6.1884 22.5092 2.13599 17.5103 2.13599C12.5114 2.13599 8.45898 6.1884 8.45898 11.1873C8.45898 16.1862 12.5114 20.2386 17.5103 20.2386Z"
        stroke={color}
        strokeWidth="4"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default DrawerStructure;
