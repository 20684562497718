import React from 'react';
import classNames from 'common/utils/classNames';
import style from './styleSecond.module.scss';
import { usePosthog } from '../../common/hooks/usePosthog';

interface Props {
  className?: string;
  textCard: string | any;
  titleCard?: string;
  logo: string;
  backColor: string;
  logoLink: {
    text: string;
    url: string;
  }[];
  reportToPosthog?: boolean;
}
const CardSecond = ({ textCard, logo, className, backColor, logoLink, titleCard, reportToPosthog = false }: Props) => {
  const { enableAnalytics, posthog } = usePosthog();

  const handleClick = () => {
    if (reportToPosthog && enableAnalytics && posthog)
      posthog.capture('Espace Pro: A téléchargé une ressource', {
        resourceName: titleCard,
        link: logoLink,
      });
  };

  return (
    <div className={classNames(style.cardContainer, className)} style={{ backgroundColor: backColor }}>
      <div className={style.headerContainer}>
        <div className={style.logoContainer}>
          <img src={logo} alt=" " height={40} className={style.logo} />
        </div>
        <div className={style.textCard}>
          <div className={style.titleContainer}>{titleCard}</div>
        </div>
      </div>
      <div className={style.textContainer}>{textCard}</div>
      {logoLink.map((l) => (
        <p style={{ cursor: 'pointer', marginBottom: 2 }}>
          <a
            href={l.url}
            target="_blank"
            rel="noreferrer noopener"
            download
            className={style.linkContainer}
            onClick={handleClick}
          >
            {l.text}
          </a>
        </p>
      ))}
    </div>
  );
};

export default CardSecond;
