import React, { useContext, useState } from 'react';
import UserContext from 'common/contexts/UserContext';
import { RouteComponentProps } from 'react-router-dom';
import { validatePassword } from 'common/utils/validation';
import { useReset } from 'common/requests/auth';
import { useForm } from 'common/hooks/useInputs';

import useAuth from 'common/hooks/useAuth';
import { decodeUri } from 'common/utils/url';

const useRenewPassword = (location: RouteComponentProps['location']) => {
  const { token } = decodeUri(location.search);
  const [resetCall, resetState] = useAuth(useReset);
  const [resetCallAdvisor, resetStateAdvisor] = useReset();
  const [errorForm, setErrorForm] = useState<string>('');

  const { user } = useContext(UserContext);
  const [state, actions] = useForm({
    initialValues: { password: '', confirmPassword: '' },
    validation: {
      password: validatePassword,
      confirmPassword: validatePassword,
    },
  });
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (actions.validateForm()) {
      if (state.values.password === state.values.confirmPassword) {
        if (user?.role !== 'user') {
          resetCallAdvisor({ variables: { password: state.values.password, token } });
        } else {
          resetCall({ variables: { password: state.values.password, token } });
        }
      } else {
        setErrorForm('Le nouveau mot de passe et confiramtion mot de passe doit être identiques');
      }
    } else {
      const error = Object.values(state.errors).filter((el) => el !== '')[0];
      setErrorForm(`${error}`);
      actions.setAllTouched(true);
    }
  };

  return { user, state, actions, errorForm, resetState, resetStateAdvisor, onSubmit };
};

export default useRenewPassword;
