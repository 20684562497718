import { FormEvent, useState, useEffect } from 'react';
import { validateEmail } from 'common/utils/validation';
import Button from 'components/Button/Button';
import Input from 'components/Form/Input/Input';
import { useForm } from 'common/hooks/useInputs';
import style from './structureForm.module.scss';

interface IProps {
  inviteAdvisor: (v: any) => void;
  inviteState: any;
  errorAPi: string;
}

const StructureForm = ({ inviteState, inviteAdvisor, errorAPi }: IProps) => {
  const [state, actions] = useForm({
    initialValues: { firstName: '', lastName: '', email: '' },
    required: ['firstName', 'lastName', 'email'],
    validation: {
      email: validateEmail,
    },
  });
  const { values } = state;
  const { handleChange } = actions;
  const [errorMsgLocal, setErrorMsgLocal] = useState('');

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!values.firstName || !values.lastName || !values.email) {
      return setErrorMsgLocal('Vous devez remplir tous les champs');
    }
    if (validateEmail(values.email)) {
      return setErrorMsgLocal('Votre adresse e-mail doit être valide (exemple@domain.com)');
    }

    return inviteAdvisor(values);
  };
  useEffect(() => {
    if (values.firstName && values.lastName && values.email) {
      setErrorMsgLocal('');
    }
  }, [values.firstName, values.lastName, values.email]);

  return (
    <div>
      <p className={style.title}>Ajouter un.e accompagnant.e</p>
      <div className={style.subTitle}>Entrez le mail de l&apos;accompagnant et une invitation lui sera envoyée</div>
      <form onSubmit={handleSubmit} className={style.formContainer}>
        <span className={style.errorText}>{errorMsgLocal || errorAPi}</span>

        <div className={style.nameUser}>
          <div className={style.inputcontainer}>
            <Input
              name="lastName"
              label="Prénom"
              placeholder="Prenom"
              onChange={handleChange}
              value={values.lastName}
              errorStyle={(errorMsgLocal !== '' && !values.lastName) || errorAPi.includes('identifiants')}
            />
          </div>
          <div className={style.inputcontainer}>
            <Input
              name="firstName"
              placeholder="Nom"
              label="Nom"
              onChange={handleChange}
              value={values.firstName}
              errorStyle={(errorMsgLocal !== '' && !values.firstName) || errorAPi.includes('identifiants')}
            />
          </div>
        </div>
        <div className={style.inputEmailcontainer}>
          <Input
            label="email"
            name="email"
            placeholder="email"
            onChange={handleChange}
            value={values.email}
            errorStyle={
              (errorMsgLocal !== '' && !values.email) ||
              errorMsgLocal.includes('e-mail') ||
              errorAPi.includes('identifiants')
            }
          />
        </div>
        <div className={style.btnContainer}>
          <div className={style.btn}>
            <Button label="Ajouter" type="submit" loader={inviteState.loading} disable={inviteState.loading} />
          </div>
        </div>
        <div className={style.link}>Besoin d’aide ?</div>
      </form>
    </div>
  );
};

export default StructureForm;
