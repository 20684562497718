import { useEffect, useState, useContext, useMemo } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import {
  useAddReference,
  AddReferenceArguments,
  useUpdateReference,
  useDeleteRef,
  useReferences,
  useReference,
} from 'common/requests/reference';
import useSnackBar from 'common/hooks/useSnackBar';
import { useDidMount } from 'common/hooks/useLifeCycle';
import userContext from 'common/contexts/UserContext';
import Modal from 'components/Modal/Modal';
import Title from 'components/Title/Title';
import { useForm } from 'common/hooks/useInputs';
import { omit, groupBy, isEmpty } from 'lodash';
import ArrowLeft from 'assets/svg/arrow-left.svg';
import ModalRefs from 'components/ModalRefs/ModalRefs';

import Plus from 'assets/svg/addCustom';
import openeye from 'assets/svg/openEye.svg';
import closeeye from 'assets/svg/closeEye.svg';
import EmptyCard from 'assets/svg/emptyCard.svg';

import Button from 'components/Button/Button';
import classesNames from 'common/utils/classNames';
import { Reference } from 'common/requests/types';
import { decodeUri } from 'common/utils/url';
import Competence, { Niveau } from './components/Competence/Competence';
import Card from '../referenceContainer/components/Card/Card';

import styles from './components/Competence/styles.module.scss';
import { usePosthog } from '../../common/hooks/usePosthog';

interface IProps {
  dataToShow?: Reference;
  onUpdate: () => void;
}
interface DataToSend {
  id: string;
  title: string;
  competences: Pick<
    {
      title: string;
      type: string;
      niveau: {
        title: string;
        sub_title: string;
      }[];
    },
    'title' | 'type' | 'niveau'
  >[];
  static?: boolean;
}
const array = [
  {
    competences: [
      { title: 'pôle organisationnel', value: 4, color: '#0087AF' },
      { title: 'pôle communicationnel', value: 4, color: '#77BB91' },
      { title: 'pôle réflexif', value: 4, color: '#F2A900' },
    ],
    nom: 'RECTEC tous secteurs',
    info: `Carte de 12 compétences faites à partir du Lorem ipsum dolor sit amet, 
      consectetur adipiscing elit, sed do eiusmod tempor incididunt .`,
  },
  {
    competences: [
      { title: 'pôle organisationnel', value: 4, color: '#0087AF' },
      { title: 'pôle communicationnel', value: 4, color: '#77BB91' },
      { title: 'pôle réflexif', value: 4, color: '#F2A900' },
    ],
    nom: 'RECTEC +',
    info: `Déclinaison officielle faite dans le cadre de Lorem ipsum dolor sit amet, 
      consectetur adipiscing elit, sed do eiusmod tempor incididunt.`,
  },
  {
    img: EmptyCard,
    nom: 'Nouvelle carte',
    info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  },
];
const competenceTypes = [
  { title: 'pôle organisationnel', type: 'organizational', color: '#0087AF' },
  { title: 'pôle communicationnel', type: 'communication', color: '#77BB91' },
  { title: 'pôle réflexif', type: 'reflective', color: '#F2A900' },
];

function isNiveauEqual(a: Niveau[], b: Niveau[]) {
  return a.map((n) => n.title + n.sub_title).join('') === b.map((n) => n.title + n.sub_title).join('');
}

const AddReference = ({ dataToShow, onUpdate }: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const { id: refId, static: staticRef } = decodeUri(location.search);
  const { user } = useContext(userContext);
  const { open } = useSnackBar();

  const [getListRefCall, getListRefState] = useReferences({ fetchPolicy: 'network-only' });
  const [deleteReferenceCall, deleteReferenceState] = useDeleteRef();
  const [GetStaticRefCall, GetStaticRefState] = useReference();
  const [dataToRender, setDataToRender] = useState<Reference>(undefined || ({} as Reference));
  const [title, setTitle] = useState('');
  const [error, setError] = useState('');
  const [errorModal, setErrorModal] = useState('');
  const [showsTypes, setShowsType] = useState<string[]>(['', '', '']);
  const [showSubsOrgs, setShowSubsOrgs] = useState(false);
  const [showSubsCom, setShowSubsCom] = useState(false);
  const [showSubsRef, setShowSubsRef] = useState(false);

  const [openModal, setOpen] = useState(false);

  const [hoverLevel, setHoverLevel] = useState<number | null>(null);
  const [selectedCmp, setSelectedCmp] = useState(
    null as { title: string; type: string; color: string; id?: string } | null,
  );
  const [openFilter, setOpenFilter] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [deletedRef, setDeleteRef] = useState('');

  const [selectedType, setSelectedType] = useState(
    null as { title: string; type: string; color: string; id?: string } | null,
  );
  const [{ values }, { handleChange, setValues }] = useForm({ initialValues: { title: '' }, required: ['title'] });
  const [addReferenceCall, addReferenceState] = useAddReference();
  const [updateReferenceCall, updateReferenceState] = useUpdateReference();
  const [competences, setCompetences] = useState(
    {} as {
      [key: string]: {
        title: string;
        niveau: Niveau[];
        id?: string;
      }[];
    },
  );

  const { enableAnalytics, posthog } = usePosthog();

  useDidMount(() => {
    getListRefCall();
  });

  useEffect(() => {
    if (dataToShow) {
      setDataToRender(dataToShow);
    }
  }, [dataToShow]);
  useEffect(() => {
    if (refId && staticRef) {
      GetStaticRefCall({ variables: { id: refId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staticRef, refId]);
  const isUpdateDisabled = useMemo(() => {
    if (dataToShow) {
      const keys = Object.keys(competences);
      const grouped = groupBy(dataToShow.competences, 'type');
      const dataKeys = Object.keys(grouped);
      if (keys.length !== dataKeys.length || keys.find((key) => !dataKeys.find((dataKey) => dataKey === key)))
        return false;
      if (keys.find((k) => competences[k].length !== grouped[k].length)) return false;
      return !keys.find((key) => {
        return competences[key].find(
          (competence) =>
            !dataToShow.competences.find(
              (c) =>
                c.id === competence.id && c.title === competence.title && isNiveauEqual(competence.niveau, c.niveau),
            ),
        );
      });
    }
    return !title;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToShow, competences, title]);
  // add uSeEffect
  useEffect(() => {
    if (addReferenceState.data) {
      open('Votre référentiel a bien été ajouté');
      history.push('/references');
      setError('');

      if (enableAnalytics) {
        posthog!.capture('Espace Pro: A créé un référentiel');
      }
    }
    // eslint-disable-next-line
  }, [addReferenceState.data]);
  // errors
  useEffect(() => {
    if (addReferenceState.error?.message) setError(addReferenceState.error?.message);
    if (updateReferenceState.error?.message) {
      setError(updateReferenceState.error?.message);
    }
  }, [addReferenceState.error, updateReferenceState.error]);
  useEffect(() => {
    if (location.search.slice(4, 28)) {
      setError('');
      setErrorModal('');
    }
  }, [location]);
  // clear data
  useEffect(() => {
    if (!selectedType) {
      setValues({ title: '' });
    }
    // eslint-disable-next-line
  }, [selectedType]);
  // initialise 1st to show
  useEffect(() => {
    if (dataToRender) {
      const c = groupBy(dataToRender.competences, 'type');
      setCompetences(c);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToRender]);
  // update uSeEffect
  useEffect(() => {
    if (updateReferenceState.data) {
      if (staticRef) {
        // GetStaticRefCall({ variables: { id: updateReferenceState.data.updateReference.id } });
        history.push(`/references?id=${updateReferenceState.data.updateReference.id}`);
      } else {
        onUpdate();
      }
      setError('');
      setErrorModal('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateReferenceState.data]);
  // initialise title
  useEffect(() => {
    if (selectedCmp?.title) {
      setValues({ title: selectedCmp?.title });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCmp?.title]);

  useEffect(() => {
    if (deleteReferenceState.data) {
      history.replace('/references');
      getListRefCall();
      setOpenFilter(false);
      setOpenDelModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteReferenceState.data]);
  // static Ref
  useEffect(() => {
    if (GetStaticRefState.data) {
      setDataToRender(GetStaticRefState.data.reference);
      setValues({ title: GetStaticRefState.data.reference.title });
      setTitle(GetStaticRefState.data.reference.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GetStaticRefState.data]);
  const onOpenUpdateCompetence = (cmp: any) => {
    setSelectedType(cmp);
    setSelectedCmp(cmp);
  };
  const onHoverLevel = (level: number | null) => {
    setHoverLevel(level);
  };
  const onCLickBtn = () => {
    if (!isEmpty(dataToRender) || staticRef) {
      const cmps = ([] as AddReferenceArguments['competences']).concat(
        ...Object.keys(competences).map((key) =>
          competences[key].map((c) => ({
            title: c.title,
            type: key,
            niveau: c.niveau.map((n) => omit(n, '__typename')),
            id: c.id,
          })),
        ),
      );
      const dataToSend: DataToSend = {
        id: location.search.slice(4, 28),
        title: staticRef ? title : dataToRender.title,
        competences: cmps.map((cmp) => omit(cmp, '__typename')),
      };
      if (staticRef) {
        dataToSend.static = true;
      }
      updateReferenceCall({
        variables: dataToSend,
      });
    } else {
      addReferenceCall({
        variables: {
          title,
          competences: ([] as AddReferenceArguments['competences']).concat(
            ...Object.keys(competences).map((key) => competences[key].map((c) => ({ ...c, type: key }))),
          ),
        },
      });
    }
  };
  const insert = (arr: any[], index: number, newItem: any) => {
    return [...arr.slice(0, index), newItem, ...arr.slice(index)];
  };
  const hasLevel = (arr: { title: string; niveau: Niveau[] }[]) => {
    let res = false;
    const r = arr.find((c) => c.niveau.length !== 0);
    if (r) {
      res = true;
    }
    return res;
  };
  const onDeleteRef = (e: React.MouseEvent<HTMLElement, MouseEvent>, id: string) => {
    e.preventDefault();
    setOpenDelModal(true);
    setDeleteRef(id);
  };
  const onClickRow = (id: string) => {
    setOpenFilter(false);
    return history.replace(`/references?id=${id}`);
  };
  const deletRef = () => {
    deleteReferenceCall({ variables: { id: deletedRef } });
  };

  if (!user?.isReferentiel) {
    return <Redirect to="/" />;
  }
  return (
    <div className={styles.containerAdd}>
      {location.pathname === '/reference/add' && (
        <div className={styles.headerRef}>
          <Title title="Mon référentiel :" className={styles.titlePage} />
          <input
            placeholder="Nommez ici votre référentiel"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              setError('');
            }}
            className={styles.inputAdd}
          />
          <div className={styles.btnShowRefs} onClick={() => setOpenFilter(!openFilter)}>
            <img src={ArrowLeft} alt="arrow" className={styles.img} />
          </div>
        </div>
      )}

      <div className={styles.bodyContent}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          <div>
            {(addReferenceState.called || updateReferenceState.called) && (
              <p className={styles.errorTextModal}>{error}</p>
            )}
          </div>
          <div className={styles.btnSaveContainer}>
            <Button
              className={styles.btnSave}
              disable={isUpdateDisabled}
              containerStyle={styles.disableAddBtn}
              label="Enregistrer"
              loader={updateReferenceState.loading || addReferenceState.loading}
              onClick={onCLickBtn}
            />
          </div>
        </div>
        <div className={styles.competenceHeader}>
          <span className={classesNames(styles.headerArray, styles.firstLabel)}>compétences</span>
          {[...Array(8)].map((a, i) => (
            <span key={a} className={classesNames(styles.headerArray, i === hoverLevel && styles.levelToAdd)}>
              {i + 1}
            </span>
          ))}
        </div>
        {competenceTypes.map((competenceType) => {
          return (
            <>
              <div className={styles.rowCompetenceContainer}>
                <div className={styles.indicateur} style={{ background: competenceType.color }} />
                <span className={styles.rowCompetence} style={{ color: competenceType.color }}>
                  {competenceType.title}
                </span>
                <button
                  onClick={() => setSelectedType(competenceType)}
                  className={styles.btnAdd}
                  style={
                    competences[competenceType.type]?.length === 5 || !dataToRender?.title
                      ? { background: 'grey', cursor: 'auto' }
                      : { background: competenceType.color }
                  }
                  disabled={competences[competenceType.type]?.length === 5 || !dataToRender?.title}
                >
                  <div className={styles.img}>
                    <Plus width="12" height="12" color="#fff" strokeWidth="3" />
                  </div>
                  <span className={styles.textAdd}>Ajouter</span>
                </button>
                {competences[competenceType.type]?.length === 5 && (
                  <span className={styles.infoAddCmp}>Vous avez déjà ajouté 5 compétences</span>
                )}

                {competences[competenceType.type] && hasLevel(competences[competenceType.type]) && (
                  <div
                    className={styles.indicateurSubs}
                    onClick={() => {
                      if (competenceType.type === 'organizational') {
                        const arr = [...showsTypes];
                        if (showSubsOrgs) {
                          arr[0] = '';
                          setShowSubsOrgs(false);
                        } else {
                          arr[0] = competenceType.type;
                          setShowSubsOrgs(true);
                        }
                        setShowsType(arr);
                      }
                      if (competenceType.type === 'communication') {
                        const arr = [...showsTypes];
                        if (showSubsCom) {
                          arr[1] = '';
                          setShowSubsCom(false);
                        } else {
                          arr[1] = competenceType.type;
                          setShowSubsCom(true);
                        }
                        setShowsType(arr);
                      }
                      if (competenceType.type === 'reflective') {
                        const arr = [...showsTypes];
                        if (showSubsRef) {
                          arr[2] = '';
                          setShowSubsRef(false);
                        } else {
                          arr[2] = competenceType.type;
                          setShowSubsRef(true);
                        }
                        setShowsType(arr);
                      }
                    }}
                  >
                    <img
                      src={
                        (showSubsOrgs && showsTypes.includes(competenceType.type)) ||
                        (showSubsCom && showsTypes.includes(competenceType.type)) ||
                        (showSubsRef && showsTypes.includes(competenceType.type))
                          ? openeye
                          : closeeye
                      }
                      alt="subs"
                    />
                    <span className={styles.indicateurSubsText}>indicateur</span>
                  </div>
                )}
              </div>
              {competences[competenceType.type]?.map((competence, i) => {
                return (
                  <Competence
                    // eslint-disable-next-line
                    key={i}
                    onNiveauAdd={(niveau, index) => {
                      if (niveau.title && niveau.sub_title) {
                        const nextCompetencesType = [...competences[competenceType.type]];

                        if (index < competence.niveau.length) {
                          const nextNiveau = [...competence.niveau];
                          nextNiveau[index] = niveau;
                          const nextCompetence = { ...competence, niveau: nextNiveau };
                          nextCompetencesType[i] = nextCompetence;
                        } else {
                          const nextCompetence = { ...competence, niveau: [...competence.niveau, niveau] };
                          nextCompetencesType[i] = nextCompetence;
                        }

                        setCompetences({ ...competences, [competenceType.type]: nextCompetencesType });
                      } else {
                        setErrorModal('Ce champ est obligatoire');
                      }
                    }}
                    onNiveauDelete={(index) => {
                      const nextCompetencesType = [...competences[competenceType.type]];
                      const nextNiveau = [...competence.niveau];
                      const newniveau = nextNiveau
                        .slice(0, index)
                        .concat(nextNiveau.slice(index + 1, nextNiveau.length));
                      const nextCompetence = { ...competence, niveau: newniveau };
                      nextCompetencesType[i] = nextCompetence;
                      setCompetences({ ...competences, [competenceType.type]: nextCompetencesType });
                    }}
                    onHoverLevel={onHoverLevel}
                    errorModal={errorModal}
                    setErrorModal={setErrorModal}
                    title={competence.title}
                    type={competenceType.type}
                    niveau={competence.niveau}
                    color={competenceType.color}
                    showsType={showsTypes}
                    onClickTitle={() =>
                      onOpenUpdateCompetence({
                        type: competenceType.type,
                        title: competence.title,
                        color: competenceType.color,
                        id: competence.id,
                      })
                    }
                  />
                );
              })}
            </>
          );
        })}

        <Modal
          isOpen={!!selectedType}
          onClose={() => {
            setSelectedType(null);
            setErrorModal('');
            setValues({ title: '' });
            setSelectedCmp(null);
          }}
          widthSize="auto"
          heightSize="auto"
          bkground="#f5f6fb"
          body={styles.bodyModal}
          withoutClose
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (selectedType) {
                const nextCompetence = { title: values.title, type: selectedType.type, niveau: [] };
                if (nextCompetence.title) {
                  if (selectedCmp) {
                    const getIndex = competences[selectedType.type].findIndex((c) => c.title === selectedType.title);
                    const currentTypesCmp = competences[selectedType.type].find((c) => c.title === selectedType.title);
                    const cmps = competences[selectedType.type].filter((c) => c.title !== selectedType.title);
                    if (currentTypesCmp?.niveau) {
                      const newData = {
                        id: currentTypesCmp.id,
                        title: values.title,
                        niveau: currentTypesCmp.niveau,
                        type: selectedType.type,
                        __typename: 'referenceCompetence',
                      };
                      const res = insert(cmps, getIndex, newData);
                      setCompetences({
                        ...competences,
                        [selectedType.type]: res,
                      });
                    }
                  } else {
                    setCompetences({
                      ...competences,
                      [selectedType.type]: competences[selectedType.type]
                        ? [...competences[selectedType.type], nextCompetence]
                        : [nextCompetence],
                    });
                  }

                  setSelectedType(null);
                } else {
                  setErrorModal('Le champ compétence est obligatoire');
                }
              }
            }}
            className={styles.modal}
          >
            <h1 className={styles.title} style={{ color: selectedType?.color || selectedType?.color }}>
              {(selectedCmp && competenceTypes.find((c) => c.type === selectedCmp.type)?.title) || selectedType?.title}
            </h1>
            <div>
              <p className={styles.labelInput}>compétence</p>
            </div>
            <textarea
              name="title"
              onChange={(e) => {
                handleChange(e);
                setErrorModal('');
              }}
              value={values.title}
              className={styles.inputModal}
              style={{
                color: selectedType?.color || selectedType?.color,
                border: errorModal ? '1px solid red' : '',
              }}
              wrap="hard"
              maxLength={100}
            />
            <span className={styles.errorTextModal}>{errorModal}</span>

            <div className={styles.addBtnModal}>
              <Button label="valider" />
            </div>
          </form>
        </Modal>
      </div>
      <Modal
        isOpen={openModal}
        onClose={() => setOpen(false)}
        className={styles.modal_confirmation}
        widthSize="95%"
        heightSize="96%"
      >
        <div>
          <div className={styles.titleModal}>Création de votre référentiel</div>
          <p className={styles.subTitleModal}>
            Vous pouvez créer votre référentiel à partir d’un modèle existant ou à partir d’une grille vierge
          </p>
          <div className={styles.cardContainerRefs}>
            {array.map((d) => (
              <Card
                key={d.nom}
                competences={d.competences}
                nom={d.nom}
                info={d.info}
                img={d.img}
                onClose={() => {
                  setOpen(false);
                  setOpenFilter(false);
                  setOpenDelModal(false);
                  setDataToRender({} as Reference);
                  setTitle('');
                }}
              />
            ))}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={openFilter}
        onClose={() => {
          setOpenFilter(false);
          setOpenDelModal(false);
        }}
        className={classesNames(styles.modal_confirmation, openDelModal && styles.modal_confirmation_transition)}
        bkground="#fff"
        widthSize="auto"
        heightSize="auto"
        body={styles.bodyModal}
        withoutClose
      >
        <ModalRefs
          listRefs={getListRefState.data?.references.data}
          onClickRow={onClickRow}
          onDeleteRef={onDeleteRef}
          setOpenDelModal={setOpenDelModal}
          openDelModal={openDelModal}
          deletedRef={deletedRef}
          deletRef={deletRef}
          setOpen={setOpen}
          open={openModal}
        />
      </Modal>
    </div>
  );
};

export default AddReference;
