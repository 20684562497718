/* eslint-disable max-len */
import Card from 'components/Card/Card';
import CardSecond from 'components/Card/CardSecond';

import React, { useEffect } from 'react';
import download from 'assets/svg/download.svg';
import print from 'assets/svg/print.svg';
import Title from 'components/Title/Title';
import style from './style.module.scss';
import { usePosthog } from '../../common/hooks/usePosthog';

const firstCard = [
  {
    id: 1,
    title: "Le livret d'activités complet ",
    text:
      "pour découvrir tous les formats d'ateliers adaptés à differents contextes d'usage et de pratique (présentiel,distanciel, formats hybrides).",
    link: 'https://assets.diagoriente.fr/activite-advisor/Livret-dactivites-Diagoriente.pdf',
  },
  {
    id: 2,
    title: 'Les fonctionnalités',
    text: 'pour faire un tour d’horizon des possibilités qu’offre Diagoriente.',
    link: 'https://assets.diagoriente.fr/activite-advisor/2-Fonctionnalites-espace-pro.pdf',
  },
  {
    id: 3,
    title: " L'atelier focus orientation",
    text:
      'pour accompagner vos publics en présentiel dans la seconde étape (exploration des intérêts pro et des pistes métiers, recherche d’immersion géolocalisée).',

    link: 'https://assets.diagoriente.fr/activite-advisor/4-Focus-orientation.pdf',
  },
  {
    id: 4,
    title: " L'atelier en distanciel",
    text: 'pour accompagner vos publics à distance dans l’intégralité du parcours.',
    link: 'https://assets.diagoriente.fr/activite-advisor/Format-distanciel.pdf',
  },
];
const secondCard = [
  {
    id: 5,
    title: 'Le cadre théorique ',
    text: 'pour comprendre le concept et étayer les pratiques du dispositif.',
    link: 'https://assets.diagoriente.fr/activite-advisor/1-Avant-propos-cadre-theorique.pdf',
  },
  {
    id: 6,
    title: "L'atelier focus bilan ",
    text:
      'pour accompagner vos publics en présentiel dans la première étape de diagnostic (introduction et complétion de la carte de compétences).',
    link: 'https://assets.diagoriente.fr/activite-advisor/3-Focus-bilan.pdf',
  },
  {
    id: 7,
    title: "L'atelier combiné focus bilan et orientation",

    text: 'pour accompagner vos publics en présentiel dans l’intégralité du parcours.',
    link: 'https://assets.diagoriente.frhttps://assets.diagoriente.fr/activite-advisor/5-Presentiel-focus-bilan-et-orientation.pdf',
  },
  {
    id: 8,
    title: 'Les ateliers hybrides ',
    text:
      'pour accompagner en mix présentiel/distanciel vos publics scolarisés (Prépa Apprentissage) ou en dispositif d’insertion (Mission Locale, E2C...).',

    link: 'https://assets.diagoriente.fr/activite-advisor/Formats-hybrides.pdf',
  },
];
const annex = [
  {
    id: 9,
    text: 'Guide utilisateur Diagoriente en distanciel',
    link: 'https://assets.diagoriente.fr/activite-advisor/Annexes/Guide-utilisateur-distanciel.pdf',
  },
  {
    id: 10,
    text: 'Référentiel RECTEC',
    link: 'https://assets.diagoriente.fr/activite-advisor/Annexes/Referentiel-RECTEC.pdf',
  },
  {
    id: 11,
    text: 'Référentiel RECTEC Engagement',
    link: 'https://assets.diagoriente.fr/activite-advisor/Annexes/Referentiel-RECTEC-Engagement.pdf',
  },
  {
    id: 12,
    text: 'Jeu de cartes - plateau',
    link: 'https://assets.diagoriente.fr/activite-advisor/Annexes/Jeu-de-cartes-plateau.pdf',
  },
  {
    id: 13,
    text: 'Jeu de cartes - cartes à découper',
    link: 'https://assets.diagoriente.fr/activite-advisor/Annexes/Jeu-de-cartes cartes-decouper.pdf',
  },
  {
    id: 14,
    text: 'Jeu de cartes engagement - plateau',
    link: 'https://assets.diagoriente.fr/activite-advisor/Annexes/Jeu-de-cartes-Engagement-plateau.pdf',
  },
  {
    id: 15,
    text: 'Jeu de cartes engagement - cartes à découper ',
    link:
      'https://assets.diagoriente.fr/activite-advisor/Annexes/Jeu-de-cartes-Engagement-cartes-de%CC%81couper.pdf',
  },
  {
    id: 16,
    text: "Fiche support entretien d'explicitation ",
    link: 'https://assets.diagoriente.fr/activite-advisor/Annexes/Fiche-support-entretien-explicitation.pdf',
  },
];
const APELDis =
{
  id: 17,
  title: "L’atelier orientation en présentiel",
  text: 'pour accompagner vos publics (APEL).',
  link: [
    { text: "Fiche APEL Animateur Presentiel", url: "https://storage.gra.cloud.ovh.net/v1/AUTH_de14c7ad0c93470abcf55f54bc322907/cms-files/Fiche_APEL_Animateur_Presentiel.pdf" },
    { text: "Fiche APEL Eleve Presentiel", url: "https://storage.gra.cloud.ovh.net/v1/AUTH_de14c7ad0c93470abcf55f54bc322907/cms-files/Fiche_APEL_Eleve_Presentiel.pdf" }
  ]
};
const APELPres =
{
  id: 18,
  title: "L’atelier orientation en distanciel",
  text: 'pour accompagner vos publics (APEL).',
  link: [
    { text: "Fiche APEL Animateur Distanciel", url: "https://storage.gra.cloud.ovh.net/v1/AUTH_de14c7ad0c93470abcf55f54bc322907/cms-files/Fiche_APEL_Animateur_Distanciel.pdf" },
    { text: "Fiche APEL Eleve Distanciel", url: "https://storage.gra.cloud.ovh.net/v1/AUTH_de14c7ad0c93470abcf55f54bc322907/cms-files/Fiche_APEL_Eleve_Distanciel.pdf" },
    { text: "Fiche APEL Parent Distanciel", url: "https://storage.gra.cloud.ovh.net/v1/AUTH_de14c7ad0c93470abcf55f54bc322907/cms-files/Fiche_APEL_Parent_Distanciel.pdf" }
  ]
};
const LivretActiviteContainer = () => {
  const { enableAnalytics, posthog } = usePosthog();

  useEffect(() => {
    if (enableAnalytics) posthog!.capture('A ouvert la page Ressources');
  }, []);

  return (
    <div className={style.layout}>
      <Title title="Ressources" />
      <div className={style.livretActiviteContainer}>
        <div className={style.headerContainer}>
          <div className={style.titleContainer}>Téléchargez</div>
          <div className={style.CardContainer}>
            <div className={style.Card}>
              {firstCard.map((i, index) => (
                <Card
                  key={`c${index.toString()}`}
                  logoLink={i.link}
                  textCard={i.text}
                  logo={download}
                  backColor="#F5F6FB"
                  titleCard={i.title}
                  reportToPosthog={enableAnalytics}
                />
              ))}
              <CardSecond
                key={APELDis.title}
                logoLink={APELDis.link}
                textCard={APELDis.text}
                logo={download}
                backColor="#F5F6FB"
                titleCard={APELDis.title}
                reportToPosthog={enableAnalytics}

              />
            </div>
            <div className={style.Card}>
              {secondCard.map((i, index) => (
                <Card
                  key={`b${index.toString()}`}
                  logoLink={i.link}
                  textCard={i.text}
                  logo={download}
                  backColor="#F5F6FB"
                  titleCard={i.title}
                  reportToPosthog={enableAnalytics}
                />
              ))}
              <CardSecond
                key={APELPres.title}
                logoLink={APELPres.link}
                textCard={APELPres.text}
                logo={download}
                backColor="#F5F6FB"
                titleCard={APELPres.title}
                reportToPosthog={enableAnalytics}

              />
            </div>
          </div>
        </div>
        <div className={style.footerContainer}>
          <div className={style.titleContainer}>Annexes à imprimer </div>
          <div className={style.cardFooterContainer}>
            {annex.map((i) => (
              <Card
                textCard={i.text}
                logo={print}
                backColor="#FFFFFF"
                className={style.cardItem}
                logoLink={i.link}
                reportToPosthog={enableAnalytics}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LivretActiviteContainer;
