import gql from 'graphql-tag';

import { MutationHookOptions } from '@apollo/client';
import { useLocalMutation } from 'common/hooks/apollo';

export const createDeleteStatsMutation = gql`
  mutation createDeleteStats($text: String!, $advisorId: ID!, $userId: ID!) {
    createDeleteStats(text: $text, advisorId: $advisorId, userId: $userId) {
      text
    }
  }
`;
export interface DeleteStatsArguments {
  text: string;
  userId: string;
  advisorId: string;
}

export const useCreateDeleteStats = (
  options: MutationHookOptions<{ createDeleteStats: string }, DeleteStatsArguments> = {},
) => useLocalMutation(createDeleteStatsMutation, options);
