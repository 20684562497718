import React, { useRef, useState, useEffect } from 'react';
import Crud, { ApisRef, CreateHeaderType } from 'components/ui/Crud/Crud';
import { IStructure } from 'common/requests/types';
import {
  useGetAllMembers,
  useRemoveAdvisorStructure,
  useGetAllLazyMembers,
  useInviteAdvisor,
} from 'common/requests/structure';
import classNames from 'common/utils/classNames';
import useSnackBar from 'common/hooks/useSnackBar';
import AddIcon from 'assets/svg/Icon ADD.svg';
import Dots from 'assets/svg/dots.svg';
import StructureFilter from 'components/Filters/StructureFilter/StructureFilter';
import ModalContainer from 'components/Modal/Modal';
import moment from 'moment';
import Button from 'components/Button/Button';
import EmptyComponents from './components/EmptyComponents';
import StructureForm from './components/StructureForm';
import classes from './structure.module.scss';

const StructureContainer = () => {
  const crudRef = useRef<ApisRef<IStructure, any>>(null);
  const [openForm, setOpenForm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedAdvisor, setSelected] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const [listStructure] = useGetAllLazyMembers({ fetchPolicy: 'network-only' });
  const [removeCall, removeState] = useRemoveAdvisorStructure();
  const [inviteCall, inviteState] = useInviteAdvisor();

  const renderFollowers = (n: number) => {
    let res = '';
    if (n === 0) res = '-';
    if (n === 1) res = `${n} profil`;
    if (n > 1) return `${n} profils`;
    return res;
  };

  const createHeaders: CreateHeaderType<any> = () => {
    return [
      {
        title: (
          <div className={classes.titleContainer}>
            <p className={classes.headerMainText}>Prénom Nom</p>
            <p className={classes.headerText}>Email</p>
            <p className={classes.headerTextLv}>Connexion</p>
            <p className={classes.headerTextLv}>Suivis</p>
            <p className={classes.headerTextLv}>Etat</p>
          </div>
        ),
        render: (row) => {
          return (
            <div className={classes.titleContainer}>
              <p className={classes.headerMainText}>{`${row.profile.firstName} ${row.profile.lastName}`}</p>
              <p className={classes.headerText}>{row.email}</p>
              <p className={classes.headerTextLv}>
                {row.nbrLogin && row.nbrLogin?.length
                  ? moment(row.nbrLogin[row.nbrLogin.length - 1].date, '').fromNow()
                  : '-'}
              </p>
              <p className={classes.headerTextLv}>{renderFollowers(row.followers)}</p>
              <p className={classes.headerTextLv}>
                {row.tutorialStep === 5 ? (
                  <span className={classes.completed}>Complété</span>
                ) : (
                  <span className={classes.NotCompleted}>Non complété</span>
                )}
              </p>
              <div className={classes.options}>
                <div
                  className={classes.boxOptions}
                  onClick={() => {
                    setOpenDelete(true);
                    setSelected(row.id);
                  }}
                >
                  <img src={Dots} alt="" />
                </div>
              </div>
            </div>
          );
        },
        key: 'name',
      },
    ];
  };
  const inviteAdvisor = (values: any) => {
    inviteCall({ variables: values });
  };
  const { open } = useSnackBar();
  useEffect(() => {
    if (removeState.data) {
      listStructure();
      setOpenDelete(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeState.data]);
  useEffect(() => {
    if (inviteState.data) {
      setOpenForm(false);
      setErrorMsg('');
      open(inviteState.data.inviteAdvisor, 'success');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteState.data]);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (inviteState.error) {
      if (!inviteState.error.graphQLErrors.length) {
        return setErrorMsg(inviteState.error.message);
      }
      if (typeof inviteState.error.graphQLErrors[0].message === 'string') {
        return setErrorMsg(inviteState.error.graphQLErrors[0].message);
      }
      if (typeof inviteState.error.graphQLErrors[0].message === 'object') {
        return setErrorMsg((inviteState.error.graphQLErrors[0].message as any).message);
      }
    }
  }, [inviteState.error]);
  return (
    <>
      <div className={classNames(classes.add)} onClick={() => setOpenForm(true)}>
        <img className={classes.icon} src={AddIcon} alt="" />
        ajouter un.E accompagnant.e
      </div>
      <Crud
        apisRef={crudRef}
        createHeaders={createHeaders}
        Filter={StructureFilter}
        list={useGetAllMembers}
        modalProps={{ className: classes.modal, body: classes.modalBody }}
        className={classes.crud}
        autoRedirect={false}
        tableProps={{
          EmptyComponent: EmptyComponents,
          classes: { container: classes.table, row: classes.tableRow, head: classes.tableRow },
        }}
      />
      {openForm && (
        <ModalContainer
          className={classes.modal}
          body={classes.modalBody}
          onClose={() => {
            setOpenForm(false);
            setErrorMsg('');
          }}
          isOpen={openForm}
        >
          <StructureForm inviteAdvisor={inviteAdvisor} inviteState={inviteState} errorAPi={errorMsg} />
        </ModalContainer>
      )}
      {openDelete && (
        <ModalContainer
          className={classes.modalDelete}
          body={classes.modalBodyDelete}
          onClose={() => setOpenDelete(false)}
          isOpen={openDelete}
        >
          <div>
            <h3 className={classes.textModalDel}>
              En supprimant ce compte conseiller, les profils qui y sont associés ne seront plus visibles depuis votre
              espace.
            </h3>
            <div className={classes.btnContainer}>
              <div className={classes.btnItem}>
                <Button
                  label="Supprimer"
                  className={classes.btnSup}
                  onClick={() => removeCall({ variables: { id: selectedAdvisor } })}
                />
              </div>
              <div className={classes.btnItem}>
                <Button label="Fermer" className={classes.btnClose} onClick={() => setOpenDelete(false)} />
              </div>
            </div>
          </div>
        </ModalContainer>
      )}
    </>
  );
};

export default StructureContainer;
