import React, { useContext, useState, useEffect } from 'react';

import moment from 'moment';
import { useListParcour, useListLazyParcour } from 'common/requests/parcours';

import Crud, { CreateHeaderType } from 'components/ui/Crud/Crud';
import { AdminParcourItem } from 'common/requests/types';
import userContext from 'common/contexts/UserContext';
import ViewParcourIcon from 'assets/svg/Icon DIAGORIENTE.svg';
import { useUpdateUser } from 'common/requests/user';
import classNames from 'common/utils/classNames';
import ParcourFilter from 'components/Filters/ParcourFilter/ParcourFilter';
import { RouteComponentProps } from 'react-router-dom';
import useUpdateUserInfo from 'utils/UpdateUserData';
import Modal from 'components/Modal/Modal';
import ModalSkills from 'components/ModalSkills/ModalSkills';
import { usePosthog } from 'common/hooks/usePosthog';
import { useCreateDeleteStats } from 'common/requests/deleteStats';
// import trash from 'assets/svg/trash.svg';
import CloseIcon from 'assets/svg/close icon.svg';

import Button from 'components/Button/Button';
import EmptyParcour from './components/EmptyParcour/EmptyParcour';
// import Stats from './Stats/Stats';
import classes from './style.module.scss';

const Parcour = ({ history }: RouteComponentProps) => {
  const { user } = useContext(userContext);
  const [updateTutoCall, updateTutoState] = useUpdateUser();
  const [createCall, createState] = useCreateDeleteStats();
  const [lisCall] = useListLazyParcour();
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedUserDelete, setSelectedUserDelete] = useState('');
  const [error, setError] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const checkboxes = [
    { id: 0, text: 'En formation' },
    { id: 1, text: 'En emploi' },
    { id: 2, text: 'Plus accompagné par ma structure' },
  ];
  const [selected, setSelected] = useState<number | undefined>(undefined);
  useUpdateUserInfo(updateTutoState.data?.updateUser);

  const { enableAnalytics, posthog } = usePosthog();

  useEffect(() => {
    if (enableAnalytics) posthog!.capture('Consulte la liste des parcours');
    // eslint-disable-next-line
  }, [enableAnalytics]);

  if (user?.isActive && user.tutorialStep === 3) {
    updateTutoCall({ variables: { tutorialStep: 4 } });
  }
  const onDelete = () => {
    if (!selected) setError('Veuillez choisir une option');
    if (user?.id && selected) {
      const dataToSend = {
        userId: selectedUserDelete,
        advisorId: user?.id,
        text: checkboxes[selected].text,
      };
      createCall({ variables: dataToSend });
    }
  };
  const createHeaders: CreateHeaderType<AdminParcourItem> = () => {
    return [
      {
        title: '',
        render: (row) => (
          <div className={classes.avatarLogo}>
            <img className={classes.avatar} src={row.userId?.logo} alt="logo" />
          </div>
        ),
        key: 'avatar',
      },
      {
        title: [
          <span key="name" className={classes.nameHeader}>
            PRÉNOM NOM
          </span>,
          <span key="email" className={classes.email}>
            EMAIL
          </span>,
          <span key="createdAt" className={classes.email}>
            DATE DE CRÉATION
          </span>,
        ],
        render: (row) =>
          row.userId && [
            <div key="name" className={classes.name} onClick={() => history.push(`/parcours/${row.userId.id}`)}>
              <span className={classes.firstSpan}>{row.userId.profile.firstName}</span>
              <span>{row.userId.profile.lastName}</span>
            </div>,
            <span key="email" className={classes.email}>
              {row.userId.email}
            </span>,
            <span key="createdAt" className={classes.email}>
              {moment(row.createdAt).format('DD/MM/YYYY')}
            </span>,
          ],
        key: 'name',
      },
      {
        title: 'CONNEXION',
        key: 'CONNEXION',
        render: (row) => {
          const date = row.userId?.nbrLogin.length
            ? row.userId.nbrLogin[row.userId.nbrLogin.length - 1].date
            : row.userId?.createdAt;
          return date ? moment(date).fromNow() : '-';
        },
      },
      {
        title: 'EMAIL CONSEILLER',
        key: 'EMAIL CONSEILLER',
        render: (row) => (
          <span key="emailAdvisor" className={classes.email}>
            {row.advisorId ? row.advisorId?.email : '-'}{' '}
          </span>
        ),
      },
      {
        title: 'ÉTAT',
        render: (row) =>
          row.completed ? (
            <span className={classNames(classes.status, classes.completed)}>Complété</span>
          ) : (
            <span className={classNames(classes.status, classes.nonCompleted)}>Non complété</span>
          ),
        key: 'completed',
      },
      {
        title: '',
        render: (row) => {
          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setSelectedUser(row.userId.id);
              }}
              className={classes.center}
            >
              <img className={classes.viewIcon} src={ViewParcourIcon} alt="" />
            </div>
          );
        },
        key: 'view',
      },
      {
        title: '',
        render: (row) => {
          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setSelectedUserDelete(row.userId.id);
                setOpenDelete(true);
              }}
              className={classes.center}
            >
              <img className={classes.iconDelete} src={CloseIcon} alt="" />
            </div>
          );
        },
        key: 'view',
      },
    ];
  };
  useEffect(() => {
    if (createState.data) {
      lisCall();
      setOpenDelete(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createState.data]);
  useEffect(() => {
    if (createState.error) {
      setError(createState.error.message);
    }
  }, [createState.error]);

  return (
    <>
      <Crud
        handleUri={(uri) => {
          if (uri.completed) {
            return { ...uri, completed: uri.completed === 'true' };
          }
          return uri;
        }}
        /* HasStats={Stats} */
        Filter={ParcourFilter}
        createHeaders={createHeaders}
        list={useListParcour}
        tableProps={{
          EmptyComponent: EmptyParcour,
        }}
      />
      <Modal close={classes.close} isOpen={Boolean(selectedUser)} onClose={() => setSelectedUser('')}>
        <ModalSkills userId={selectedUser} />
      </Modal>
      <Modal
        widthSize="40%"
        heightSize="40%"
        close={classes.close}
        isOpen={openDelete}
        onClose={() => {
          setOpenDelete(false);
          setError('');
          setSelected(undefined);
        }}
      >
        <div>
          <p className={classes.titleDelete}>Voulez-vous retirer ce parcours de votre espace pro ?</p>
          <p className={classes.errorText}>{error}</p>

          {checkboxes.map((check) => (
            <div className={classes.rowCheckBox}>
              <input
                checked={selected === check.id}
                onChange={() => {
                  setSelected(check.id);
                  setError('');
                }}
                type="checkbox"
                value={selected || undefined}
              />
              <span>{check.text}</span>
            </div>
          ))}
          <Button label="Retirer de mon espace" onClick={() => onDelete()} />
        </div>
      </Modal>
    </>
  );
};

export default Parcour;
