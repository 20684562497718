import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Input from 'components/Form/Input/Input';
import Button from 'components/Button/Button';
import { useAcceptStructure } from 'common/requests/structure';
import Logo from 'assets/svg/diagoriente_logo_bg_transparent.svg';
import { decodeUri } from 'common/utils/url';
import { useForm } from 'common/hooks/useInputs';
import { validatePassword } from 'common/utils/validation';

import style from './join.module.scss';

const JoinStructure = () => {
  const location = useLocation();
  const history = useHistory();
  const [state, actions] = useForm({
    initialValues: { confirmPassword: '', password: '' },
    validation: {
      password: validatePassword,
      confirmPassword: validatePassword,
    },
  });
  const { values } = state;
  const [error, setError] = useState('');
  const [acceptStructureCall, acceptStructureState] = useAcceptStructure();

  const { token } = decodeUri(location.search);
  const onSubmit = () => {
    if (values.password !== values.confirmPassword) {
      return setError('Mot de passe et confirmation mot de passe ne correspondent pas');
    }
    if (!values.password) {
      return setError('Veuillez renseigner votre mot de passe');
    }
    if (!values.confirmPassword) {
      return setError('Veuillez confirmer votre mot de passe');
    }
    if (validatePassword(values.password)) {
      return setError(`Votre mot de passe doit comporter au moins six caractères 
      avec des lettres minuscules et majuscules, des chiffres et des symboles`);
    }
    if (validatePassword(values.confirmPassword)) {
      return setError(`Votre mot de passe doit comporter au moins six caractères 
      avec des lettres minuscules et majuscules, des chiffres et des symboles`);
    }
    const dataToSend = {
      password: values.password,
      token,
    };
    return acceptStructureCall({ variables: dataToSend });
  };
  useEffect(() => {
    if (acceptStructureState.error) {
      setError(acceptStructureState.error.message);
    }
  }, [acceptStructureState.error]);
  useEffect(() => {
    if (acceptStructureState.data) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptStructureState.data]);
  return (
    <div className={style.container}>
      <a href="https://diagoriente.beta.gouv.fr/" className={style.logoContainer}>
        <div>
          <img src={Logo} alt="logo" className={style.logo} />
        </div>
      </a>
      <div className={style.boxRegister}>
        <div className={style.content}>
          <p className={style.title}>Rejoindre la structure</p>
          <span className={style.info}>
            Utilisez au moins 12 caractères avec des lettres minuscules et majuscules, des chiffres et des symboles
          </span>
          <div className={style.errorText}>{error}</div>
          <div style={{ width: 420 }}>
            <Input
              name="password"
              type="password"
              label="Mot de passe"
              value={values.password}
              onChange={actions.handleChange}
              className={style.inputsContainer}
            />
          </div>
          <div style={{ width: 420 }}>
            <Input
              name="confirmPassword"
              type="password"
              label="confirmation Mot de passe"
              value={values.confirmPassword}
              onChange={actions.handleChange}
              className={style.inputsContainer}
            />
          </div>
          <div style={{ width: 420 }}>
            <Button label="Valider" onClick={onSubmit} loader={acceptStructureState.loading} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinStructure;
