/* eslint-disable max-len */
import React from 'react';

interface Props {
  color: string;
  width: string;
  height: string;
}

const TutoLogo = ({ color, width, height }: Props) => {
  return (
    <svg width={width} height={height} viewBox="0 0 699 688" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M421.118 598.909C543.908 538.849 772.728 461.289 675.438 183.329C578.148 -94.6309 458.068 13.9591 365.468 59.2491C272.868 104.539 -109.782 406.589 30.6282 584.169C165.048 754.179 253.548 680.869 421.118 598.909Z"
        fill={color}
      />
      <path
        d="M374.289 326.889C368.759 325.449 344.959 327.329 333.219 334.839C321.489 342.349 290.479 477.889 290.479 477.889C290.479 477.889 326.099 474.799 350.819 426.229C375.539 377.659 374.289 326.889 374.289 326.889Z"
        fill="#D60051"
      />
      <path
        d="M360.948 289.649C352.208 297.749 349.998 301.279 341.168 307.899C332.338 314.519 308.498 309.219 312.908 339.689C317.328 370.149 335.428 386.049 349.108 377.659C362.788 369.269 406.948 325.559 406.948 325.559L375.158 291.559L360.948 289.649Z"
        fill="#10255E"
      />
      <path
        d="M404.748 330.859C398.568 336.599 381.508 354.969 373.698 347.989C365.888 341.009 370.748 327.329 370.748 327.329C370.748 327.329 332.778 322.469 327.038 345.869C325.198 353.349 320.008 353.569 323.948 384.279C327.258 410.119 339.178 466.079 343.818 481.409C374.728 481.849 455.078 482.289 455.078 482.289L473.178 430.629L454.638 351.599L404.748 330.859Z"
        fill="#D60051"
      />
      <path d="M422.679 248.899L425.499 246.359L422.679 248.899Z" fill="#00CFFF" />
      <path
        d="M475.778 295.219C468.748 270.279 483.418 259.559 471.408 242.149C462.298 228.949 442.068 217.979 424.468 224.059C419.388 225.809 415.428 228.629 412.468 231.379C416.818 236.379 421.148 241.359 425.488 246.359L422.668 248.899L420.848 250.539L407.798 236.039L407.678 235.909C391.208 229.619 370.168 233.679 359.488 257.129C361.058 257.809 363.208 259.309 364.788 259.999C364.778 260.039 368.988 262.389 368.978 262.429C368.978 262.429 370.968 263.749 375.598 265.959C378.688 267.509 377.818 272.329 377.818 272.329C377.808 272.379 396.798 274.079 396.788 274.129C400.058 275.619 401.868 277.659 405.178 279.209C408.328 279.779 393.228 278.739 393.078 279.579L412.248 282.739C417.478 285.199 403.458 284.519 408.538 286.989C409.118 287.269 409.708 287.559 410.278 287.839C410.938 288.159 411.648 287.379 412.298 287.699C413.738 287.009 415.268 287.699 416.898 287.609C417.958 287.549 419.048 287.639 420.128 287.869C426.788 289.289 431.028 295.849 429.608 302.509C428.178 309.169 421.628 313.409 414.958 311.989C413.298 311.639 411.798 310.959 410.488 310.049L410.458 310.169L405.168 330.569C405.188 330.579 405.208 330.579 405.218 330.589C409.468 332.029 413.388 333.679 416.948 335.389C417.888 335.839 418.818 336.299 419.708 336.759C420.488 337.169 421.268 337.569 422.008 337.979C422.808 338.409 423.578 338.829 424.328 339.259C425.578 339.969 426.768 340.669 427.878 341.349C432.148 344.159 438.338 348.569 445.108 354.739C450.638 359.779 455.398 364.959 459.558 370.179C466.898 366.329 482.848 408.219 489.048 402.829C533.198 364.419 504.058 334.839 498.698 328.829C489.098 320.229 479.968 310.059 475.778 295.219Z"
        fill="#10255E"
      />
      <path
        d="M431.179 379.319C436.569 378.559 441.809 377.319 446.839 375.629L430.929 376.369C431.019 377.359 431.099 378.339 431.179 379.319Z"
        fill="#7B52ED"
      />
      <path
        d="M405.149 330.759L404.889 331.109C405.099 330.839 405.229 330.679 405.229 330.679L405.249 330.589C405.229 330.579 405.209 330.579 405.199 330.569L405.169 330.669L405.149 330.759Z"
        fill="#7B52ED"
      />
      <path
        d="M408.489 236.229C414.419 238.719 419.209 243.339 422.679 248.899L425.499 246.359C421.159 241.359 416.819 236.379 412.479 231.379C410.879 232.889 409.279 234.389 407.679 235.889L407.689 235.909C407.959 236.009 408.219 236.119 408.489 236.229Z"
        fill="#7B52ED"
      />
      <path
        d="M420.858 250.539L422.678 248.899C419.208 243.349 414.418 238.729 408.488 236.229C408.218 236.119 407.958 236.009 407.688 235.899L407.808 236.029L420.858 250.539Z"
        fill="#7B52ED"
      />
      <path
        d="M361.498 265.379C361.508 265.329 361.518 265.279 361.538 265.219C361.498 265.299 361.458 265.379 361.418 265.459C361.358 265.569 361.318 265.679 361.258 265.779C361.328 265.719 361.388 265.669 361.438 265.619C361.468 265.549 361.478 265.469 361.498 265.379Z"
        fill="#4B6BC4"
      />
      <path
        d="M361.448 265.629C361.658 265.449 361.788 265.359 361.788 265.359C361.878 265.389 361.988 265.429 362.098 265.459C361.918 265.379 361.728 265.299 361.548 265.219C361.548 265.219 361.548 265.229 361.538 265.229C361.528 265.279 361.508 265.329 361.498 265.389C361.478 265.469 361.468 265.549 361.448 265.629Z"
        fill="#4B6BC4"
      />
      <path
        d="M367.499 297.089L375.789 299.799C375.789 299.799 375.289 302.659 375.909 306.719L384.459 304.309C384.609 304.269 384.779 304.309 384.879 304.429C384.989 304.549 385.019 304.709 384.959 304.859C383.529 308.559 380.379 311.099 377.059 311.379C378.319 315.039 380.599 319.069 384.679 322.679C383.859 322.749 383.039 322.789 382.219 322.789C385.309 322.909 389.109 322.659 393.269 321.479C392.469 323.199 391.619 325.049 390.719 326.949C395.879 327.799 400.719 329.059 405.189 330.569L410.479 310.169L410.509 310.049C406.559 307.289 404.429 302.349 405.499 297.339C406.139 294.349 407.809 291.849 410.069 290.129C410.749 289.609 411.489 289.159 412.259 288.789C411.599 288.469 416.889 287.899 416.229 287.579C415.649 287.299 412.399 279.769 411.809 279.489C407.579 277.429 398.409 274.579 396.649 273.009C396.279 272.679 395.839 272.419 395.349 272.309C391.939 271.519 381.709 266.529 379.129 265.359C378.209 269.259 374.499 284.079 372.599 286.989C370.689 289.949 367.499 297.089 367.499 297.089ZM383.699 281.449C384.689 281.449 385.489 282.249 385.489 283.239C385.489 284.229 384.689 285.029 383.699 285.029C382.709 285.029 381.909 284.229 381.909 283.239C381.899 282.259 382.709 281.449 383.699 281.449Z"
        fill="#4B6BC4"
      />
      <path
        d="M371.638 338.699C371.628 338.899 369.328 335.959 369.318 336.159C366.998 351.389 368.878 358.019 371.528 359.779C374.178 361.549 379.368 362.539 382.898 359.999C386.428 357.459 397.468 342.009 397.468 342.009C397.488 341.979 398.658 341.259 398.678 341.239L401.108 338.149C401.228 337.979 403.378 333.059 404.888 331.119L405.148 330.769L405.178 330.679L405.208 330.579C400.738 329.069 395.898 327.809 390.738 326.959C386.138 336.729 378.328 338.439 371.638 338.699Z"
        fill="#4B6BC4"
      />
      <path
        d="M358.539 302.529C357.509 299.559 356.959 297.169 356.749 296.199C356.749 296.199 356.019 292.909 356.059 287.819C356.069 285.369 356.269 282.489 356.809 279.349C357.539 275.159 358.889 270.499 361.259 265.789C359.279 267.579 352.549 275.259 356.229 294.399C356.839 297.559 357.579 300.399 358.439 302.939C358.479 302.829 358.529 302.709 358.569 302.599C358.559 302.579 358.549 302.559 358.539 302.529Z"
        fill="#2F50A8"
      />
      <path
        d="M377.828 272.329C377.828 272.329 366.028 266.819 362.088 265.459C367.278 267.739 372.508 270.079 377.788 272.489C377.808 272.429 377.818 272.379 377.828 272.329Z"
        fill="#2F50A8"
      />
      <path
        d="M358.568 302.599C358.528 302.709 358.478 302.829 358.438 302.939C362.098 313.829 367.868 319.339 374.358 321.559C374.358 321.529 374.358 321.509 374.358 321.509C365.498 318.389 360.848 309.119 358.568 302.599Z"
        fill="#2F50A8"
      />
      <path
        d="M356.058 287.819C356.018 292.919 356.748 296.199 356.748 296.199C356.968 297.169 357.508 299.559 358.538 302.529C358.548 302.549 358.558 302.579 358.558 302.599C358.578 302.549 358.598 302.499 358.618 302.449C360.178 297.999 360.778 293.729 360.948 289.649C361.078 286.269 360.928 283.019 360.778 279.879C360.698 278.249 360.628 276.659 360.598 275.099C360.088 274.869 359.778 274.599 359.778 274.309C359.778 274.019 360.078 273.759 360.588 273.529C360.588 273.339 360.588 273.159 360.588 272.969C360.588 272.549 360.608 272.139 360.618 271.729C360.708 269.629 360.938 267.589 361.438 265.629C361.378 265.679 361.318 265.729 361.258 265.789C358.888 270.509 357.528 275.159 356.808 279.349C356.268 282.489 356.068 285.369 356.058 287.819Z"
        fill="#2F50A8"
      />
      <path
        d="M359.419 267.429C358.919 269.399 360.709 269.639 360.619 271.729C360.609 272.139 360.589 272.559 360.589 272.969C360.589 273.149 360.589 273.339 360.589 273.529C361.599 273.069 363.469 272.769 365.609 272.769C368.829 272.769 371.439 273.459 371.439 274.309C371.439 275.159 368.829 275.849 365.609 275.849C363.479 275.849 361.609 275.539 360.599 275.089C360.629 276.649 360.699 278.249 360.779 279.869C360.919 283.009 361.079 286.259 360.949 289.639C360.779 293.729 360.179 297.999 358.619 302.439C358.599 302.489 358.579 302.539 358.559 302.589C360.839 309.109 365.489 318.369 374.359 321.499C374.359 321.499 377.429 322.589 382.209 322.769C383.029 322.769 383.849 322.729 384.669 322.659C380.589 319.049 378.309 315.019 377.049 311.359C376.849 311.379 376.649 311.389 376.449 311.389C376.289 311.389 376.129 311.379 375.969 311.369C373.409 311.199 371.569 309.689 370.699 308.799C370.599 308.689 370.559 308.539 370.589 308.399C370.629 308.259 370.739 308.149 370.879 308.109L375.879 306.699C375.259 302.639 375.759 299.779 375.759 299.779L367.469 297.069C367.469 297.069 370.659 289.929 372.589 286.979C374.489 284.069 378.339 269.249 379.269 265.349C374.119 263.729 367.829 261.149 362.639 258.869C362.549 258.859 359.639 267.249 359.419 267.429ZM363.119 281.449C362.129 281.449 361.329 280.649 361.329 279.659C361.329 278.669 362.129 277.869 363.119 277.869C364.109 277.869 364.909 278.669 364.909 279.659C364.919 280.649 364.109 281.449 363.119 281.449Z"
        fill="#2F50A8"
      />
      <path
        d="M384.959 304.859C385.019 304.709 384.989 304.549 384.879 304.429C384.769 304.309 384.609 304.269 384.459 304.309L375.909 306.719C376.129 308.149 376.489 309.729 377.059 311.379C380.379 311.099 383.529 308.559 384.959 304.859Z"
        fill="#10255E"
      />
      <path
        d="M370.898 308.119C370.758 308.159 370.648 308.269 370.608 308.409C370.568 308.549 370.608 308.699 370.718 308.809C371.588 309.699 373.428 311.209 375.988 311.379C376.148 311.389 376.308 311.399 376.468 311.399C376.668 311.399 376.868 311.389 377.068 311.369C376.498 309.719 376.138 308.139 375.918 306.709L370.898 308.119Z"
        fill="#10255E"
      />
      <path
        d="M360.599 273.529C360.089 273.759 359.789 274.029 359.789 274.309C359.789 274.599 360.089 274.859 360.609 275.099C360.599 274.569 360.599 274.049 360.599 273.529Z"
        fill="#10255E"
      />
      <path
        d="M371.438 274.319C371.438 273.469 368.828 272.779 365.608 272.779C363.468 272.779 361.598 273.089 360.588 273.539C360.588 274.059 360.588 274.579 360.598 275.109C361.608 275.559 363.478 275.869 365.608 275.869C368.838 275.859 371.438 275.169 371.438 274.319Z"
        fill="#10255E"
      />
      <path
        d="M390.518 278.359C391.178 278.669 391.848 278.989 392.508 279.289C392.708 279.379 392.898 279.479 393.098 279.569C393.248 278.729 390.818 277.599 387.668 277.029C387.708 277.049 387.748 277.069 387.798 277.089C388.698 277.509 389.608 277.939 390.518 278.359Z"
        fill="#10255E"
      />
      <path
        d="M381.618 277.509C381.468 278.349 383.908 279.489 387.078 280.059C390.238 280.629 392.928 280.409 393.078 279.569C392.878 279.479 392.688 279.379 392.488 279.289C391.828 278.979 391.158 278.659 390.498 278.359C389.588 277.929 388.678 277.509 387.768 277.079C387.728 277.059 387.688 277.039 387.638 277.019C387.628 277.019 387.618 277.019 387.608 277.009C384.458 276.449 381.768 276.669 381.618 277.509Z"
        fill="#10255E"
      />
      <path
        d="M364.848 268.679C364.698 269.519 367.138 270.659 370.308 271.229C373.468 271.799 376.158 271.579 376.308 270.739C376.108 270.649 375.918 270.549 375.718 270.459C375.058 270.149 374.388 269.829 373.728 269.529C372.818 269.099 371.908 268.679 370.998 268.249C370.958 268.229 370.918 268.209 370.868 268.189C370.858 268.189 370.848 268.189 370.838 268.179C367.688 267.619 364.998 267.839 364.848 268.679Z"
        fill="#10255E"
      />
      <path
        d="M383.699 285.039C384.687 285.039 385.489 284.238 385.489 283.249C385.489 282.261 384.687 281.459 383.699 281.459C382.71 281.459 381.909 282.261 381.909 283.249C381.909 284.238 382.71 285.039 383.699 285.039Z"
        fill="#10255E"
      />
      <path
        d="M363.119 277.869C362.129 277.869 361.329 278.669 361.329 279.659C361.329 280.649 362.129 281.449 363.119 281.449C364.109 281.449 364.909 280.649 364.909 279.659C364.919 278.669 364.109 277.869 363.119 277.869Z"
        fill="#10255E"
      />
      <path
        d="M420.188 292.739C420.378 292.829 420.568 292.929 420.758 293.019L424.228 290.879L424.588 291.359C424.788 293.229 422.328 299.309 417.548 302.569C417.148 303.049 416.728 303.539 416.268 304.019C416.088 304.199 414.088 306.109 410.868 308.619C410.838 308.639 410.808 308.669 410.778 308.689L410.868 308.719L410.528 310.059C411.838 310.969 413.338 311.639 414.998 311.999C421.658 313.419 428.218 309.179 429.648 302.519C431.068 295.859 426.828 289.299 420.168 287.879C419.078 287.649 417.988 287.559 416.938 287.619C415.298 287.699 413.728 288.119 412.288 288.809C412.298 288.819 412.308 288.819 412.328 288.829C414.948 290.129 417.588 291.429 420.188 292.739Z"
        fill="#4B6BC4"
      />
      <path
        d="M410.068 290.139C407.818 291.849 406.148 294.349 405.498 297.349C404.428 302.359 406.558 307.299 410.508 310.059L410.848 308.719L410.758 308.689C410.788 308.669 410.818 308.649 410.848 308.619C414.068 306.109 416.058 304.209 416.248 304.019C416.708 303.539 417.138 303.049 417.528 302.569C416.068 303.559 414.408 304.309 412.518 304.579C416.118 302.399 413.358 297.569 413.358 297.569L420.738 293.019C420.548 292.929 420.358 292.829 420.168 292.739C417.568 291.429 414.928 290.129 412.278 288.819"
        fill="#4B6BC4"
      />
      <path
        d="M341.078 332.989C340.688 333.179 340.298 333.369 339.938 333.559C340.318 333.389 340.708 333.219 341.108 333.049C341.098 333.029 341.088 333.009 341.078 332.989Z"
        fill="#4E1ABA"
      />
      <path
        d="M374.279 325.809L374.459 321.939L374.469 321.599C374.429 321.589 374.399 321.569 374.359 321.559C374.339 321.789 374.269 322.899 374.159 325.799C374.199 325.799 374.239 325.799 374.279 325.809C374.279 325.799 374.279 325.799 374.279 325.809Z"
        fill="#2F50A8"
      />
      <path
        d="M374.279 325.879V325.799C374.279 325.899 374.269 325.999 374.269 326.099L374.279 325.879Z"
        fill="#2F50A8"
      />
      <path
        d="M376.819 325.789H377.279C381.979 325.819 386.459 326.249 390.729 326.959C391.619 325.059 392.469 323.209 393.279 321.489C389.109 322.669 385.329 322.679 382.239 322.559C379.599 322.569 376.979 322.459 374.479 321.619L374.469 321.959L374.289 325.829C374.309 325.829 374.339 325.829 374.369 325.829C375.209 325.789 376.019 325.779 376.819 325.789Z"
        fill="#2F50A8"
      />
      <path
        d="M369.209 333.949C368.909 341.019 366.629 356.329 371.339 359.119C378.029 358.859 386.139 336.729 390.729 326.959C386.459 326.249 384.139 323.239 379.439 323.209L377.449 324.019C376.659 324.009 374.419 323.419 374.209 324.239C373.619 326.519 370.779 327.769 370.749 327.769L369.789 330.269L369.209 333.949Z"
        fill="#2F50A8"
      />
      <path
        d="M374.479 321.549C374.449 321.529 374.399 321.519 374.369 321.509C374.369 321.509 374.369 321.529 374.369 321.559C374.409 321.569 374.439 321.589 374.479 321.599V321.549Z"
        fill="#2F50A8"
      />
      <path
        d="M374.369 321.509C374.399 321.529 374.449 321.539 374.479 321.549V321.599C376.979 322.439 379.589 322.799 382.229 322.779C377.439 322.599 374.369 321.509 374.369 321.509Z"
        fill="#2F50A8"
      />
      <path
        d="M374.278 325.809C374.278 325.799 374.278 325.799 374.278 325.809C374.238 325.809 374.208 325.809 374.168 325.809C374.198 325.809 374.238 325.809 374.278 325.809Z"
        fill="#2F50A8"
      />
      <path
        d="M374.268 326.109C374.268 326.009 374.278 325.909 374.278 325.809C374.238 325.809 374.208 325.809 374.168 325.809C374.098 327.549 374.028 329.939 373.938 333.189L374.268 326.109Z"
        fill="#2F50A8"
      />
      <path
        d="M424.589 291.359L424.229 290.879L420.759 293.019C420.869 293.079 420.979 293.129 421.089 293.189C421.519 293.409 421.939 293.619 422.369 293.829C422.189 294.319 421.999 294.819 421.799 295.309C421.219 296.779 420.539 298.149 420.539 298.149C420.539 298.149 419.429 300.269 417.539 302.559C422.329 299.309 424.779 293.229 424.589 291.359Z"
        fill="#2F50A8"
      />
      <path
        d="M421.799 295.319C421.999 294.829 422.189 294.329 422.369 293.839C421.939 293.619 421.519 293.409 421.089 293.199C420.979 293.139 420.869 293.089 420.759 293.029L413.379 297.579C413.379 297.579 416.139 302.409 412.539 304.589C414.419 304.319 416.089 303.579 417.549 302.579C419.429 300.289 420.549 298.169 420.549 298.169C420.549 298.169 421.209 296.789 421.799 295.319Z"
        fill="#2F50A8"
      />
      <path
        d="M444.038 406.799C444.698 418.059 455.958 429.979 443.378 443.889C430.798 457.799 436.758 456.469 436.758 456.469L483.778 442.559L444.038 406.799Z"
        fill="#B80045"
      />
      <path
        d="M404.748 330.859C412.258 330.859 463.468 330.859 482.458 373.689C501.448 416.519 516.018 448.309 509.828 461.549C503.638 474.789 462.148 437.709 462.148 437.709C462.148 437.709 432.128 387.379 431.688 386.049C431.238 384.719 404.748 330.859 404.748 330.859Z"
        fill="#FF458B"
      />
      <path
        d="M406.518 463.309C394.598 462.429 387.978 458.889 383.998 459.339C380.028 459.779 365.458 465.519 362.808 469.489C360.158 473.459 356.188 480.529 356.628 482.739C357.068 484.949 412.258 482.299 412.258 482.299L406.518 463.309Z"
        fill="#4B6BC4"
      />
      <path
        d="M462.148 437.709L396.358 459.789L396.798 482.749C396.798 482.749 511.148 491.579 509.828 461.559C508.508 431.519 462.148 437.709 462.148 437.709Z"
        fill="#FF458B"
      />
      <path
        d="M321.298 332.629C322.178 322.919 324.388 316.739 324.388 309.669C324.388 302.599 323.948 295.099 320.418 290.239C316.888 285.379 304.078 278.319 304.078 278.319C304.078 278.319 301.868 267.719 300.548 260.659C299.228 253.599 281.118 247.409 280.238 255.359C279.358 263.309 282.448 284.499 284.658 288.029C286.868 291.559 299.228 304.809 299.228 304.809L293.928 323.349L321.298 332.629Z"
        fill="#4B6BC4"
      />
      <path
        d="M344.698 367.059C340.278 375.449 332.778 434.609 336.308 450.059C325.708 460.209 304.518 476.109 295.688 476.549C286.858 476.989 313.788 367.499 313.788 367.499C313.788 367.499 321.438 328.939 352.048 327.469C353.828 325.709 344.698 367.059 344.698 367.059Z"
        fill="#B80045"
      />
      <path
        d="M326.928 327.769C326.928 327.769 323.578 434.619 314.778 449.629C305.978 464.639 299.278 484.069 279.158 476.999C270.288 473.879 256.468 450.449 260.718 416.509C266.108 373.519 291.788 317.839 297.178 311.429C302.628 314.079 326.928 327.769 326.928 327.769Z"
        fill="#FF458B"
      />
      <path
        d="M462.588 347.639C464.838 350.129 455.468 346.079 449.788 353.379C441.348 364.199 435.608 388.869 444.048 406.799C434.338 410.329 407.848 412.099 407.848 412.099L393.718 366.179L391.948 349.399L405.248 330.139C405.248 330.139 449.778 333.509 462.588 347.639Z"
        fill="#D60051"
      />
      <path
        d="M286.868 272.579C288.188 275.669 288.048 275.959 292.898 281.259C297.758 286.559 308.498 289.799 313.798 290.679C319.098 291.559 322.778 298.329 323.218 295.679C322.558 285.079 312.918 283.609 304.088 278.309C295.258 273.009 286.868 272.579 286.868 272.579Z"
        fill="#2F50A8"
      />
      <path
        d="M259.488 188.249C270.968 190.019 370.748 273.239 370.748 273.239C370.748 273.239 374.278 282.729 366.778 285.819C359.278 288.909 239.178 266.389 239.178 266.389C239.178 266.389 275.158 269.999 282.008 240.339C284.658 228.869 248.008 186.479 259.488 188.249Z"
        fill="#4E1ABA"
      />
      <path
        d="M415.938 288.469L410.288 287.829C410.288 287.829 406.808 290.449 407.038 291.039C407.258 291.629 407.628 292.659 407.628 292.659C407.628 292.659 410.278 293.469 411.528 292.729C412.768 292.009 415.938 288.469 415.938 288.469Z"
        fill="#4B6BC4"
      />
      <path
        d="M247.488 267.639C269.304 267.639 286.988 249.493 286.988 227.109C286.988 204.725 269.304 186.579 247.488 186.579C225.673 186.579 207.988 204.725 207.988 227.109C207.988 249.493 225.673 267.639 247.488 267.639Z"
        fill="#7B52ED"
      />
      <path
        d="M247.488 259.319C264.819 259.319 278.868 244.898 278.868 227.109C278.868 209.32 264.819 194.899 247.488 194.899C230.158 194.899 216.108 209.32 216.108 227.109C216.108 244.898 230.158 259.319 247.488 259.319Z"
        fill="#00CFFF"
      />
      <path
        d="M362.808 469.499C362.808 469.499 380.248 466.409 378.698 467.949C377.148 469.499 361.038 471.699 361.038 471.699L362.808 469.499Z"
        fill="#2F50A8"
      />
      <path
        d="M359.268 476.339C362.148 475.139 370.968 475.679 373.618 475.459C376.268 475.239 378.038 475.899 374.498 477.229C370.968 478.549 357.058 480.539 357.058 480.539C357.058 480.539 356.618 477.439 359.268 476.339Z"
        fill="#2F50A8"
      />
    </svg>
  );
};

export default TutoLogo;
