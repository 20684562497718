import React from 'react';
import AddAdvisor from 'assets/svg/AddAdvisor.svg';
import classes from '../structure.module.scss';

const EmptyComponents = () => {
  return (
    <div className={classes.info}>
      <p className={classes.titleExp}>Ajouter les accompagnant.E.s</p>
      <p className={classes.subTitleExp}>Entrez les noms des accompagnant.e.s et invitez-les à compléter leur pofil </p>
      <img src={AddAdvisor} alt="" />
    </div>
  );
};

export default EmptyComponents;
