import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'common/hooks/useInputs';
import { useUpdateUser } from 'common/requests/user';
import userContext from 'common/contexts/UserContext';
import useUpdateUserInfo from 'utils/UpdateUserData';
import { validateEmail, validatePassword, isStringEmpty } from 'common/utils/validation';
import _ from 'lodash';
import Title from 'components/Title/Title';
import Input from 'components/Form/Input/Input';
import Button from 'components/Button/Button';
import classNames from 'utils/classNames';
import style from './style.module.scss';

const AccountContainer = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const [GQLerrorMsg, setGQLErrorMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [confirmEmail, setConfirmationEmail] = useState('');
  const [confirmPassword, setConfirmationPassword] = useState('');
  const { user } = useContext(userContext);
  const [updateUser, updateUserState] = useUpdateUser();
  const structure = user?.structure ? user.structure.name : '';
  const institution = user ? user.profile.institution || structure : '';
  const [isClicked, setIsCkicked] = useState(false);

  const [state, actions] = useForm({
    initialValues: {
      firstName: user ? user.profile.firstName : '',
      lastName: user ? user.profile.lastName : '',
      logo: '',
      email: user ? user.email : '',
      password: '',
      oldPassword: '',
      location: '',
      institution: user ? institution : '',
      codeGroupe: '',
      acceptCondition: false,
    },
    validation: {
      firstName: isStringEmpty,
      lastName: isStringEmpty,
      email: validateEmail,
      password: validatePassword,
      oldPassword: validatePassword,
      institution: isStringEmpty,
    },
    required: ['firstName', 'lastName', 'email', 'institution'],
  });
  const onChangeConfirmationEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    setConfirmationEmail(value);
  };
  const onChangeConfirmationPassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    setConfirmationPassword(value);
  };
  const { values, errors } = state;
  useUpdateUserInfo(updateUserState.data?.updateUser);
  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const onShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };
  const onShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const callUpdate = () => {
    const error = Object.values(errors).filter((e) => e !== '')[0];
    if (
      confirmEmail === '' &&
      values.email === '' &&
      values.lastName === '' &&
      values.firstName === '' &&
      values.password === '' &&
      confirmPassword === '' &&
      values.institution === ''
    ) {
      return setErrorMsg('tous les champs vides');
    }
    if (values.email !== user?.email) {
      if (confirmEmail !== values.email) {
        return setErrorMsg('Email et confirmation Email ne correspondent pas');
      }
    }
    if (confirmPassword !== values.password) {
      return setErrorMsg('Mot de passe et confirmation mot de passe ne correspondent pas');
    }
    if (values.oldPassword !== '' && values.password !== '') {
      if (values.oldPassword === values.password) {
        return setErrorMsg("Le nouveau mot de passe et l'ancien ne peuvent pas être identiques");
      }
    }
    if (error) {
      return setErrorMsg(error);
    }

    updateUser({ variables: _.pickBy(values, (value) => value) });
    return setErrorMsg('Vos modifications ont bien été enregistrées');
  };

  useEffect(() => {
    if (updateUserState.called && updateUserState.error) {
      setGQLErrorMsg(updateUserState.error.message);
    } else setGQLErrorMsg('');
  }, [updateUserState]);

  const handleRequiredInputs = () => {
    if (!isClicked) setIsCkicked(true);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (
      updateUserState.called &&
      updateUserState.error === undefined &&
      updateUserState.data &&
      errorMsg === 'Vos modifications ont bien été enregistrées'
    ) {
      refreshPage();
    }
  }, [updateUserState.data]);

  const displayError = () => {
    if (errorMsg === 'Vos modifications ont bien été enregistrées' && GQLerrorMsg) {
      return GQLerrorMsg;
    }
    if (errorMsg.includes('doit comporter')) {
      return '';
    }
    return errorMsg;
  };

  return (
    <div className={style.accountContainer}>
      <Title title="Mon compte" />
      <div className={style.accountBox}>
        <div className={style.error}>{displayError()}</div>
        <div className={style.formLogin}>
          <form className={style.twoInputs}>
            <Input
              label="Prénom"
              name="firstName"
              placeholder="Michel"
              onChange={actions.handleChange}
              value={values.firstName}
              containerClassName={style.miniInput}
              errorStyle={isClicked && values.firstName === ''}
              required
            />
            <Input
              label="Nom"
              name="lastName"
              onChange={actions.handleChange}
              value={values.lastName}
              placeholder="Niane"
              containerClassName={style.miniInput}
              errorStyle={isClicked && values.lastName === ''}
              required
            />
          </form>
          <div className={style.divider} />
          <Input
            label="Email"
            name="email"
            placeholder="exemple@gmail.com"
            onChange={actions.handleChange}
            value={values.email}
            errorStyle={
              isClicked &&
              (values.email === '' ||
                errorMsg.includes('e-mail') ||
                errorMsg.toLowerCase().includes('email') ||
                GQLerrorMsg.toLowerCase().includes('email'))
            }
            required
          />
          <Input
            label="confirmation email"
            name="confirmEmail"
            placeholder="exemple@gmail.com"
            onChange={onChangeConfirmationEmail}
            value={confirmEmail}
            errorStyle={isClicked && errorMsg.toLowerCase().includes('confirmation email')}
            required
          />
          <div className={style.divider} />
          <Input
            label="ancien mot de passe"
            name="oldPassword"
            onChange={actions.handleChange}
            value={values.oldPassword}
            showPassword={() => onShowOldPassword()}
            type={!showOldPassword ? 'password' : 'text'}
            containerClassName={style.miniInput}
            errorStyle={
              isClicked &&
              (errorMsg.toLowerCase().includes('ancien') ||
                validatePassword(values.oldPassword) !== '' ||
                GQLerrorMsg.includes('Ancien mot de passe'))
            }
          />
          <div className={style.twoInputs}>
            <Input
              label="nouveau mot de passe"
              name="password"
              onChange={actions.handleChange}
              showPassword={() => onShowPassword()}
              value={values.password}
              type={!showPassword ? 'password' : 'text'}
              containerClassName={style.miniInput}
              errorStyle={
                isClicked &&
                ((errorMsg.includes('mot de passe') && (errorMsg.includes('actuel') || errorMsg.includes('nouveau'))) ||
                  validatePassword(values.password) !== '' ||
                  GQLerrorMsg.includes('mot de passe actuel'))
              }
            />

            <Input
              label="Confirmation"
              name="confirmPassword"
              onChange={onChangeConfirmationPassword}
              value={confirmPassword}
              showPassword={() => onShowConfirmPassword()}
              type={!showConfirmPassword ? 'password' : 'text'}
              containerClassName={style.miniInput}
              errorStyle={isClicked && errorMsg.includes('confirmation mot de passe')}
            />
          </div>
          <p
            className={classNames(
              style.rules,
              (validatePassword(values.oldPassword) !== '' || validatePassword(values.password) !== '') &&
                style.ErrorRules,
            )}
          >
            Utilisez au moins 12 caractères avec des lettres (minuscules et majuscules), des chiffres et des symboles
          </p>
          <div className={style.divider} />
          <Input
            label="institution"
            name="institution"
            placeholder="Exemple"
            onChange={actions.handleChange}
            value={values.institution}
            errorStyle={isClicked && values.institution === ''}
            required
            disabled={user?.role === 'super-advisor' || !_.isEmpty(user?.structure?.name)}
          />
          <div className={style.divider} />
          <Button
            onClick={() => {
              callUpdate();
              handleRequiredInputs();
            }}
            label="enregistrer les modifications"
          />
        </div>
        <div className={style.conditions}>
          La Délégation générale à l'emploi et à la formation professionnelle (DGEFP) traite{" "}
          les données recueillies par ce formulaire pour traiter votre demande de création de compte.{" "}
          Les données marquées par des astérisques sont obligatoires pour traiter votre demande.{" "}
          Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos droits,{" "}
          reportez-vous à la{" "}
          <a href="https://diagoriente.beta.gouv.fr/politique-de-confidentialite" target="_blank">
            politique de confidentialité
          </a>.
        </div>
      </div>
    </div>
  );
};

export default AccountContainer;
