import React from 'react';
import classNames from 'common/utils/classNames';
import style from './style.module.scss';
import { usePosthog } from '../../common/hooks/usePosthog';

interface Props {
  className?: string;
  textCard: string | any;
  titleCard?: string;
  logo: string;
  backColor: string;
  logoLink: string;
  reportToPosthog?: boolean;
}
const Card = ({ textCard, logo, className, backColor, logoLink, titleCard, reportToPosthog = false }: Props) => {
  const { enableAnalytics, posthog } = usePosthog();

  const handleClick = () => {
    if (reportToPosthog && enableAnalytics && posthog)
      posthog.capture('Espace Pro: A téléchargé une ressource', {
        resourceName: titleCard,
        link: logoLink,
      });
  };

  return (
    <>
      <a
        href={logoLink}
        target="_blank"
        rel="noreferrer noopener"
        download
        className={style.linkContainer}
        onClick={handleClick}
      >
        <div className={classNames(style.cardContainer, className)} style={{ backgroundColor: backColor }}>
          <div className={style.logoContainer}>
            <img src={logo} alt=" " height={40} className={style.logo} />
          </div>
          <div className={style.textCard}>
            <div className={style.titleContainer}>{titleCard}</div>
            <div className={style.textContainer}>{textCard}</div>
          </div>
        </div>
      </a>
    </>
  );
};

export default Card;
