import React, { useState, FormEvent, useRef, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Theme, Reference } from 'common/requests/types';
import { useThemesAdvisor, useAddThemeAdvisor, useLazyThemesAdvisor } from 'common/requests/themes';
import { useReferences, useDefaultReferences } from 'common/requests/reference';
import { useDidMount } from 'common/hooks/useLifeCycle';
import { useLazyGroups } from 'common/requests/groupes';
import classNames from 'common/utils/classNames';
import { useForm } from 'common/hooks/useInputs';
import ModalContainer from 'components/Modal/Modal';
import Input from 'components/Form/Input/Input';
import Button from 'components/Button/Button';
import Crud, { ApisRef, CreateHeaderType } from 'components/ui/Crud/Crud';
import Plus from 'assets/svg/addCustom';
import AddIcon from 'assets/svg/Icon ADD.svg';
import RefLogo from 'assets/svg/drawer/DrawerReferentiel';
import GroupeLogo from 'assets/svg/drawer/DrawerGroupes';
import Tooltip from 'rc-tooltip';
import ExperienceFilter from '../../components/Filters/ExperiencesFilter/ExperienceFilter';
import 'rc-tooltip/assets/bootstrap_white.css';
import classes from './experinces.module.scss';
import EmptyComponents from './components/EmptyComponents';
import Select from './components/Select';
import SelectGroupe from './components/SelectGroupe';
import { usePosthog } from '../../common/hooks/usePosthog';

const Experiences = ({ history }: RouteComponentProps) => {
  const crudRef = useRef<ApisRef<Theme, any>>(null);

  const [open, setOpen] = useState(false);
  const [openGroupe, setOpenGroupe] = useState(false);
  const [openRef, setOpenRef] = useState(false);
  const [defaultSelect, setDefault] = useState(false);

  const [currentSteps, setCurrentSteps] = useState(0);
  // const [heightModal, setHeightModal] = useState('40%');
  const [activities, setActivities] = useState(['']);
  const [fieldsGroupes, setFieldsGroupes] = useState(['']);
  const [groupeSelect, setSelected] = useState<number>(-1);

  const [refSelect, setRefSelect] = useState('');
  const [defaultref, setDefaultRef] = useState('Choisi un Réferentiel');
  const [refSelected, setRefSelected] = useState<Reference | null>(null);
  const [defaultSelected, setDefaultSelected] = useState('');

  const [maxLevel, setMaxLevel] = useState<number>(0);
  const [allowedLevels, setAllowedLevels] = useState<number[]>([]);
  // const [levelLastSelected, setLastLevel] = useState<number>(0);

  const [GroupsLocal, setGroupsLocal] = useState<
    {
      id: string;
      code: string;
      title: string;
    }[]
  >([]);

  const [refereneceCall, referenceState] = useReferences();
  const [referenceDefaultCall, referenceDefaultState] = useDefaultReferences();
  const [getGroups, groupsState] = useLazyGroups({ fetchPolicy: 'network-only' });
  const [addThemeAdvisor, addThemeAdvisorState] = useAddThemeAdvisor();
  const [listThemesAdvisor] = useLazyThemesAdvisor({ fetchPolicy: 'network-only' });
  const { enableAnalytics, posthog } = usePosthog();

  const [state, actions] = useForm({ initialValues: { title: '', activities: [] }, required: ['title'] });
  const { values } = state;
  const { handleChange } = actions;
  const [errorMsg, setErrorMsg] = useState('');
  const [isClicked, setIsCkicked] = useState(false);

  const handleRequiredInput = () => {
    if (!isClicked) setIsCkicked(true);
  };

  useDidMount(() => {
    refereneceCall();
    referenceDefaultCall();
    getGroups();
  });
  useEffect(() => {
    if (groupsState.data) {
      setGroupsLocal(groupsState.data?.groupes.data);
    }
  }, [groupsState.data]);

  const handleFirstSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (values.title.length < 3) {
      setErrorMsg('Le nom du expérience doit contenir au moins 3 lettres');
    } else {
      setCurrentSteps(1);
      // setHeightModal('70%');
      setErrorMsg('');
    }
  };
  const handleSecondSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (activities.length < 1) {
      setErrorMsg('activités obligatoire');
    } else {
      setCurrentSteps(2);
      // setHeightModal('70%');
      setErrorMsg('');
    }
  };

  const handleLastSubmit = () => {
    const acts: string[] = activities;
    const dataToSend: {
      theme: string;
      activities: string[];
      groups: string[];
      reference: string;
      levels?: number[];
    } = {
      theme: values.title,
      activities: acts.filter((act) => act.length !== 0),
      groups: fieldsGroupes.filter((gpr) => gpr.length !== 0),
      reference: refSelect || defaultSelected,
    };
    /* if (refSelect || defaultSelected) {
      dataToSend.reference = refSelect || defaultSelected;
    } */

    if (allowedLevels.length) {
      dataToSend.levels = allowedLevels;
    }
    if (
      refSelect &&
      !allowedLevels.length &&
      refSelected?.title !== 'engagement' &&
      refSelected?.title !== 'Défaut (référentiel Diagoriente)'
    ) {
      return setErrorMsg('Veuillez choisir au minimum 1 niveau');
    }
    if (fieldsGroupes[0] === '' && (!refSelect || !defaultSelected)) {
      return setErrorMsg('Tous les champs sont obligatoires');
    }
    if (!fieldsGroupes.length) {
      return setErrorMsg('Veuillez choisir au moins un groupe ');
    }
    if (!refSelect && !defaultSelected) {
      return setErrorMsg('Veuillez choisir au moins un réferentiel');
    }

    return addThemeAdvisor({ variables: { ...dataToSend } });
  };

  const restFields = () => {
    actions.setValues({ title: '', activities: [] });
    setActivities(['']);
    setFieldsGroupes(['']);
    setErrorMsg('');
    setRefSelect('');
    setRefSelected(null);
    setAllowedLevels([]);
    setCurrentSteps(0);
    setMaxLevel(0);
  };
  const handleAdd = () => {
    const value = [...activities, ''];
    setActivities(value);
  };
  const handleAddGroupe = () => {
    const value = [...fieldsGroupes, ''];
    setFieldsGroupes(value);
    setErrorMsg('');
  };
  const handleChangeFields = (i: any, event: any) => {
    const value = [...activities];
    value[i] = event.target.value;
    setActivities(value);
  };

  const levels = [1, 2, 3, 4, 5, 6, 7, 8];
  const createHeaders: CreateHeaderType<Theme> = () => {
    return [
      {
        title: (
          <div style={{ flex: 1 }}>
            EXPÉRIENCE
            <span className={classes.headerText}>ACTIVITÉS</span>
          </div>
        ),
        render: (row) => (
          <>
            <span className={classes.expTitle}>{row.title}</span>
            <span className={classes.headerText}>
              {row.activities.slice(0, 1).map((act) => `${act.title}, `)}
              <span>...</span>
            </span>
          </>
        ),
        key: 'name',
      },
      {
        title: <div className={classes.addContainer} />,
        render: (row) => (
          <div className={classes.actions}>
            <div
              className={classes.btnActon}
              onClick={() => history.push(row.reference ? `/references?id=${row.reference?.id}` : '/reference/add')}
            >
              <div className={classes.wrapperBtn}>
                <RefLogo color="#10255e" />
                <div className={classes.selectedOption}>{row.reference?.title}</div>
              </div>
            </div>
            {row.groups && row.groups.length === 1 ? (
              <div
                className={classes.btnActon}
                onClick={() => history.push(`/parcours?code=${row.groups && row.groups[0].code}`)}
              >
                <div className={classes.wrapperBtn}>
                  <GroupeLogo color="#10255e" />
                  <div className={classes.selectedOption}>{row.groups && row.groups[0].title}</div>
                </div>
              </div>
            ) : (
              <Tooltip
                overlayClassName={classes.tooltip}
                placement="top"
                overlay={() => (
                  <ul>
                    {row.groups?.map((g) => (
                      <li key={g.title} onClick={() => history.push(`/parcours?code=${g.code}`)}>
                        {g.title}
                      </li>
                    ))}
                  </ul>
                )}
                overlayStyle={{ borderRadius: '10px', border: 'none' }}
                overlayInnerStyle={{
                  backgroundColor: '#10255e',
                  borderRadius: '10px',
                  color: '#ffffff',
                  fontSize: '12px',
                  padding: '10',
                  cursor: 'pointer',
                }}
                arrowContent={<div className="rc-tooltip-arrow-inner" />}
              >
                <div className={classes.btnActon}>
                  <div className={classes.wrapperBtn}>
                    <GroupeLogo color="#10255e" />
                    <div className={classes.selectedOption}>
                      {row.groups && row.groups.length > 1
                        ? `${row.groups.length} Groupes`
                        : row.groups && row.groups[0].title}
                    </div>
                  </div>
                </div>
              </Tooltip>
            )}
          </div>
        ),
        key: 'actions',
      },
    ];
  };
  const onSelectLevels = (level: number) => {
    if (level <= maxLevel) {
      if (!allowedLevels.length) setAllowedLevels([level]);
      else if (level === allowedLevels[0] || level === allowedLevels[allowedLevels.length - 1])
        setAllowedLevels(allowedLevels.filter((a) => a !== level));
      else {
        const left = Math.abs(level - allowedLevels[0]);
        const right = Math.abs(level - allowedLevels[allowedLevels.length - 1]);
        let first = allowedLevels[0];
        let last = allowedLevels[allowedLevels.length - 1];
        if (left >= right) {
          last = level;
        } else {
          first = level;
        }
        const array: number[] = [];
        for (let i = first; i <= last; i += 1) {
          array.push(i);
        }
        setAllowedLevels(array);
      }
    }
  };
  useEffect(() => {
    if (addThemeAdvisorState.data) {
      setOpen(false);
      setIsCkicked(false);
      setOpenGroupe(false);
      setOpenRef(false);
      setSelected(-1);
      setCurrentSteps(0);
      restFields();
      listThemesAdvisor();
      setRefSelected(null);
      setMaxLevel(1);
      setAllowedLevels([]);
      setActivities(['']);
      setFieldsGroupes(['']);
      setErrorMsg('');
      setRefSelect('');
      setRefSelected(null);
      setAllowedLevels([]);
      setCurrentSteps(0);
      setDefaultRef('Choisi un Réferentiel');

      if (enableAnalytics) {
        posthog!.capture('Espace Pro : A crée une expérience');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addThemeAdvisorState.data]);

  const dataGroups = useMemo(() => GroupsLocal?.filter((gl) => !fieldsGroupes.find((id) => gl.id === id)), [
    fieldsGroupes,
    GroupsLocal,
  ]);

  const refData =
    referenceState.data && referenceDefaultState.data
      ? [...referenceState.data.references.data, ...referenceDefaultState.data?.referencesDefault.data]
      : referenceDefaultState.data?.referencesDefault.data;
  const steps = [
    <>
      <h1 className={classes.title}>Ajouter une expérience</h1>
      <div className={classes.error}>{errorMsg}</div>
      <form onSubmit={handleFirstSubmit} className={classes.inputGroupeStyle}>
        <Input
          value={values.title}
          onChange={handleChange}
          label="Nom de l’expérience"
          name="title"
          war={classes.war}
          containerClassName={classes.input}
          errorStyle={(isClicked && values.title === '') || errorMsg !== ''}
          required
        />
        <Button label="valider" className={classes.validerButton} onClick={handleRequiredInput} />
      </form>
      <span className={classes.aide}>Besoin d&apos;aide ?</span>
    </>,
    <>
      <h1 className={classes.title}>Ajoutez des activités à votre expérience</h1>
      <div className={classes.error}>{errorMsg}</div>
      <form onSubmit={handleSecondSubmit} className={classes.inputGroupeStyle}>
        {activities.map((field, idx) => {
          return (
            <div key={`${idx * 2}`}>
              <Input
                value={field}
                onChange={(e) => handleChangeFields(idx, e)}
                label={`Activité ${idx + 1}`}
                name="title"
                war={classes.war}
                containerClassName={classes.input}
              />
            </div>
          );
        })}
        <div onClick={handleAdd} className={classes.outlineAddBtn}>
          <Plus width="20" height="20" color="#10255E" strokeWidth="1" />
          <span className={classes.LabelAddAct}>ajouter activité</span>
        </div>
        <Button label="valider" className={classes.validerButton} />
      </form>
      <span className={classes.aide}>Besoin d&apos;aide ?</span>
    </>,
    <>
      <h1 className={classes.title}>Ajoutez un référentiel et partagez à un groupe</h1>
      <h3 className={classes.warningText}>
        La publication de ce référentiel auprès d’un groupe d’utilisateurs ne vous permettra plus de le modifier par la
        suite.
      </h3>
      <div className={classes.error}>{errorMsg}</div>
      <form className={classes.inputGroupeStyle}>
        <span className={classes.labelSelect}>référentiel</span>
        <Select
          test={referenceState.data?.references.data.length !== 0}
          title={refSelected?.title || defaultref}
          options={refData || referenceDefaultState.data?.referencesDefault.data}
          defaultSelect={defaultSelect}
          openRef={openRef}
          setRefSelect={setRefSelect}
          setRefSelected={setRefSelected}
          setOpenRef={setOpenRef}
          setDefault={setDefault}
          setDefaultRef={setDefaultRef}
          setDefaultSelected={setDefaultSelected}
          setMaxLevel={setMaxLevel}
          errorStyle={
            currentSteps === 2 &&
            ((errorMsg === 'Tous les champs sont obligatoires' && !refSelect && !defaultSelected) ||
              errorMsg === 'Veuillez choisir au moins un réferentiel')
          }
        />

        {/*  eslint-disable-next-line max-len */}
        {refSelected && refSelected.title !== 'engagement' && refSelected.title !== 'Défaut (référentiel Diagoriente)' && (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span className={classes.labelSelect}>Les niveaux à extraire </span>
              <span
                className={classNames(classes.labelSelect, classes.pointerText)}
                onClick={() => {
                  setAllowedLevels([]);
                  setErrorMsg('');
                }}
              >
                (réinitialiser)
              </span>
            </div>

            <div
              className={classes.levelsContainer}
              style={{
                border: errorMsg === 'Veuillez choisir au minimum 1 niveau' ? '1px solid #ff0060' : '',
                background: errorMsg === 'Veuillez choisir au minimum 1 niveau' ? '#ffeef4' : '',
              }}
            >
              {levels.map((level) => (
                <div
                  className={classNames(
                    classes.levelItem,
                    allowedLevels.includes(level) ? classes.selectedLevelInside : '',
                    level === allowedLevels[allowedLevels.length - 1] ? classes.selectedLevel : '',
                    level === allowedLevels[0] ? classes.selectedLevel : '',
                  )}
                  onClick={level <= maxLevel ? () => onSelectLevels(level) : () => {}}
                  key={level}
                >
                  <span className={classNames(classes.levelText, level > maxLevel && classes.disableLevelItem)}>
                    {level}
                  </span>
                </div>
              ))}
            </div>
          </>
        )}
        <SelectGroupe
          fieldsGroupes={fieldsGroupes}
          setOpenGroupe={setOpenGroupe}
          openGroupe={openGroupe}
          setSelected={setSelected}
          GroupsLocal={GroupsLocal}
          dataGroups={dataGroups}
          groupeSelect={groupeSelect}
          setFieldsGroupes={setFieldsGroupes}
          errorStyle={
            currentSteps === 2 &&
            (errorMsg === 'Veuillez choisir au moins un groupe ' ||
              (errorMsg === 'Tous les champs sont obligatoires' && fieldsGroupes[0] === ''))
          }
        />

        {dataGroups.length !== 0 && (
          <div onClick={handleAddGroupe} className={classes.outlineAddBtn}>
            <Plus width="20" height="20" color="#10255E" strokeWidth="1" />
            <span className={classes.LabelAddAct}>ajouter groupe à qui partager</span>
          </div>
        )}
        <Button
          type="button"
          label="valider"
          className={classes.validerButton}
          onClick={handleLastSubmit}
          loader={addThemeAdvisorState.loading}
          disable={addThemeAdvisorState.loading}
        />
      </form>
      <span className={classes.aide}>Besoin d&apos;aide ?</span>
    </>,
  ];

  return (
    <div className={classes.experienceContainer}>
      <div className={classNames(classes.add)} onClick={() => setOpen(true)}>
        <img className={classes.icon} src={AddIcon} alt="" />
        ajouter une expérience
      </div>
      <Crud
        apisRef={crudRef}
        createHeaders={createHeaders}
        Filter={ExperienceFilter}
        list={useThemesAdvisor}
        modalProps={{ className: classes.modal, body: classes.modalBody }}
        className={classes.crud}
        autoRedirect={false}
        tableProps={{
          EmptyComponent: EmptyComponents,
          classes: { container: classes.table, row: classes.tableRow, head: classes.tableRow },
        }}
      />
      <ModalContainer
        isOpen={open}
        onClose={() => {
          setOpen(false);
          setIsCkicked(false);
          setCurrentSteps(0);
          restFields();
        }}
        className={classes.modal_confirmation}
        widthSize="50%"
        heightSize="auto"
      >
        <div className={classes.bodyModalContent}>{steps[currentSteps]}</div>
      </ModalContainer>
    </div>
  );
};

export default Experiences;
