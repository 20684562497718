import { useRef } from 'react';
import ArrowLeft from 'assets/svg/arrow-left.svg';
import useOnclickOutside from 'common/hooks/useOnclickOutside';
import classNames from 'utils/classNames';

import classes from '../experinces.module.scss';

interface IProps {
  test: boolean;
  title: string;
  options: any;
  defaultSelect: boolean;
  openRef: boolean;
  setRefSelect: (s: string) => void;
  setRefSelected: (s: any) => void;
  setOpenRef: (d: boolean) => void;
  setDefault: (d: boolean) => void;
  setDefaultRef: (d: string) => void;
  setDefaultSelected: (d: string) => void;
  setMaxLevel: (d: number) => void;
  errorStyle?: boolean;
}

const Select = ({
  test,
  title,
  options,
  defaultSelect,
  openRef,
  setRefSelect,
  setRefSelected,
  setOpenRef,
  setDefault,
  setDefaultRef,
  setDefaultSelected,
  setMaxLevel,
  errorStyle,
}: IProps) => {
  const divRef = useRef<HTMLDivElement>(null);
  const divRefDefault = useRef<HTMLDivElement>(null);

  useOnclickOutside(divRef, () => {
    setOpenRef(false);
  });
  useOnclickOutside(divRefDefault, () => {
    setDefault(false);
  });
  return (
    <div
      className={classNames(classes.btnShowRefs, errorStyle && classes.inputError)}
      onClick={() => (test ? setOpenRef(!openRef) : setDefault(!defaultSelect))}
    >
      <span className={classes.selectedOption}>{title}</span>
      <img src={ArrowLeft} alt="arrow" className={classes.img} />
      {openRef && test && (
        <div className={classes.optionsContainer} ref={divRef}>
          {options?.map(
            (r: {
              competences: { id: string; title: string; niveau: { title: string; subTitle: string }[] }[];
              id: string;
              title: string;
            }) => {
              return (
                <p
                  key={r.id}
                  className={classes.option}
                  onClick={() => {
                    setRefSelect(r.id);
                    setRefSelected(r);
                    const res: number[] = [];
                    r.competences.map((c) => res.push(c.niveau.length));
                    const max = Math.max(...res);
                    return setMaxLevel(max);
                  }}
                >
                  {r.title}
                </p>
              );
            },
          )}
        </div>
      )}
      {defaultSelect && (
        <div className={classes.optionsContainer} ref={divRefDefault}>
          {options.map((r: any) => (
            <p
              key={r.title}
              className={classes.option}
              onClick={() => {
                setDefaultRef(r.title);
                setDefaultSelected(r.id);
              }}
            >
              {r.title}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
