import gql from 'graphql-tag';

import { QueryHookOptions } from '@apollo/client';
import { useLocalQuery, useLocalLazyQuery } from 'common/hooks/apollo';

import { Competence } from './types';

export const CompetencesQuery = gql`
  query Competences($theme: ID, $reference: ID) {
    competences(theme: $theme, reference: $reference) {
      data {
        id
        title
        type
        niveau {
          title
          sub_title
        }
      }
    }
  }
`;

export interface CompetencesResponse {
  competences: {
    data: {
      id: string;
      title: string;
      type: string;
      niveau: {
        title: string;
        sub_title: string;
      }[];
    }[];
  };
}

export interface CompetencesResponseArgs {
  theme?: string;
  reference?: string;
}

export const useCompetences = (options: QueryHookOptions<CompetencesResponse, CompetencesResponseArgs> = {}) =>
  useLocalQuery<CompetencesResponse, CompetencesResponseArgs>(CompetencesQuery, options);

export const competencesLazyQuery = gql`
  query Competences($search: String, $page: Int, $perPage: Int, $theme: ID!) {
    competences(search: $search, page: $page, perPage: $perPage, theme: $theme) {
      perPage
      page
      totalPages
      count
      data {
        id
        title
        type
        niveau {
          title
          sub_title
        }
      }
    }
  }
`;

export interface CompetencesArguments {
  search?: string;
  page?: number;
  perPage?: number;
  theme: string;
}

export interface CompetencesLazyResponse {
  competences: {
    data: Competence[];
    perPage: number;
    page: number;
    totalPages: number;
    count: number;
  };
}

export const useLazyCompetences = (options: QueryHookOptions<CompetencesLazyResponse, CompetencesArguments> = {}) =>
  useLocalLazyQuery(competencesLazyQuery, options);

export const competenceQuery = gql`
  query Competence($id: ID!) {
    competence(id: $id) {
      id
      title
      rank
      niveau {
        title
        sub_title
      }
    }
  }
`;

export interface CompetenceResponse {
  competence: Competence;
}

export const useCompetence = (options: QueryHookOptions<CompetenceResponse, { id: string }> = {}) =>
  useLocalLazyQuery(competenceQuery, options);
