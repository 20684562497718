import { useRef } from 'react';
import ArrowLeft from 'assets/svg/arrow-left.svg';
import classNames from 'common/utils/classNames';
import useOnclickOutside from 'common/hooks/useOnclickOutside';

import classes from '../experinces.module.scss';

interface IProps {
  fieldsGroupes: string[];
  setOpenGroupe: (d: boolean) => void;
  openGroupe: boolean;
  setSelected: (d: number) => void;
  groupeSelect: number;
  GroupsLocal: {
    id: string;
    code: string;
    title: string;
  }[];
  dataGroups: {
    id: string;
    code: string;
    title: string;
  }[];
  setFieldsGroupes: (d: string[]) => void;
  errorStyle?: boolean;
}
const SelectGroupe = ({
  fieldsGroupes,
  openGroupe,
  groupeSelect,
  dataGroups,
  setOpenGroupe,
  setSelected,
  GroupsLocal,
  setFieldsGroupes,
  errorStyle,
}: IProps) => {
  const divGroupe = useRef<HTMLDivElement>(null);
  useOnclickOutside(divGroupe, () => setOpenGroupe(false));
  return (
    <div>
      {fieldsGroupes.map((field, idx) => (
        <div key={`${field}-${idx * 2}`}>
          <span className={classes.labelSelect}>{`groupe ${idx + 1}`}</span>
          <div
            className={classNames(classes.btnShowRefs, errorStyle && classes.inputError)}
            onClick={() => {
              setOpenGroupe(!openGroupe);
              setSelected(idx);
            }}
          >
            <span className={classes.selectedOption}>{GroupsLocal.find((grp) => field === grp.id)?.title}</span>
            <img src={ArrowLeft} alt="arrow" className={classes.img} />
            {openGroupe && groupeSelect === idx && dataGroups.length !== 0 && (
              <div className={classes.optionsContainer} ref={divGroupe}>
                {dataGroups.map((e) => (
                  <p
                    key={e.title}
                    className={classNames(classes.option)}
                    onClick={() => {
                      const newFieldsGroups = [...fieldsGroupes];
                      newFieldsGroups[idx] = e.id;
                      setFieldsGroupes(newFieldsGroups);
                    }}
                  >
                    {e.title}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelectGroupe;
