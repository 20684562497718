import Image from 'assets/svg/empty-parcour.svg';

import classes from './styles.module.scss';

const EmptyParcour = () => (
  <div className={classes.container}>
    <div className={classes.content}>
      <img src={Image} alt="" className={classes.img} />
      <div className={classes.absolute_fill}>
        <span className={classes.title}>En attente</span>
        <span className={classes.subtitle}>
          Personne n’a encore accepté d’invitation à rejoindre le groupe.{' '}
          <a href="/groupes" className={classes.groupsLink}>
            Envoyez des invitations
          </a>
        </span>
      </div>
    </div>
  </div>
);

export default EmptyParcour;
