import React, { useState, useEffect } from 'react';

import { validateEmail } from 'common/utils/validation';
import { useForgot } from 'common/requests/auth';
import { useForm } from 'common/hooks/useInputs';

const useForgotPassword = () => {
  const [sent, setSend] = useState(false);
  const [forgotCall, forgotState] = useForgot();
  const [state, actions] = useForm({
    initialValues: { email: '' },
    validation: {
      email: validateEmail,
    },
  });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (actions.validateForm()) {
      forgotCall({ variables: { email: state.values.email } });
    } else {
      actions.setAllTouched(true);
    }
  };
  useEffect(() => {
    if (forgotState.data && !forgotState.error) {
      setSend(true);
    }
  }, [forgotState.data, forgotState.error]);

  return {
    forgotState,
    sent,
    onSubmit,
    state,
    actions,
  };
};

export default useForgotPassword;
