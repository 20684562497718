import React, { useState, useEffect, useContext } from 'react';
import classNames from 'common/utils/classNames';
import SearchInput from 'components/Form/SearchInput/SearchInput';
import userContext from 'common/contexts/UserContext';
import Title from 'components/Title/Title';
import Select from 'components/Form/Select/Select';
import classes from '../ParcourFilter/styles.module.scss';

interface StructureFilterProps {
  onChange: (filter: { completed?: string; search?: string; page?: number; perPage?: number }) => void;
  initialValues: { completed?: string; search?: string; page?: number; perPage?: number };
  data?: any[];
  isFilterEmpty: boolean;
}
const selectClasses = {
  container: classes.selectComplete,
  options: classes.selectOptions,
  option: classes.selectOption,
};
const StructureFilter = ({ onChange, initialValues, data, isFilterEmpty }: StructureFilterProps) => {
  const [search, setSearch] = useState(initialValues.search || '');
  const [completed, setCompleted] = useState('');
  const { user } = useContext(userContext);
  const handleChange = () => {
    const filter: { search?: string } = {};
    if (search) filter.search = search;
    onChange({ ...filter, completed });
  };
  useEffect(() => {
    if (completed === 'all') handleChange();
    // eslint-disable-next-line
  }, [completed]);
  useEffect(() => {
    if (search === '') handleChange();
    // eslint-disable-next-line
  }, [search]);
  return (
    <div className={classNames(classes.row, classes.container)}>
      <div className={classes.row}>
        <Title title="Structure :" className={classes.titlePage} />
        <span className={classes.nameStructure}>{user?.structure?.name}</span>

        {data?.length || !isFilterEmpty ? (
          <>
            <SearchInput
              onSearch={handleChange}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleChange();
                }
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Rechercher"
            />
            <Select
              classes={selectClasses}
              onClickItem={(e) => {
                setCompleted(e);
                onChange({ search, completed: e });
              }}
              options={[
                { value: 'all', label: 'Tous les états' },
                { value: 'complet', label: 'Complété' },
                { value: 'not-complete', label: 'Non complété' },
              ]}
              value={completed.toString()}
              label="Tous les états"
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default StructureFilter;
